import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import './Message.css';
import { formatUTCToRelative, getTimeDiff } from '../utils/formatTime';
import Linkify from 'react-linkify';
import MessageAttachment from './MessageAttachment';
import MessageCTACard from './MessageCTACard';
import moment from 'moment';

import { InlineYesNoQuestionPrompt } from '@digitalpharmacist/messaging-forms';

const componentDecorator = (href, text, key) => (
    <a href={href} key={key} target="_blank" rel="noopener noreferrer">
        {text}
    </a>
);

function MessageText(props) {
    const { text } = props;

    return <Linkify componentDecorator={componentDecorator}>{text}</Linkify>;
}

function MessageContent(props) {
    const { message, prompt } = props;
    let content = null;

    if (message.content_type === 'attachment') {
        content = <MessageAttachment message={message} />;
        // prevent rendering an empty bubble for the scenario that text does not exist on the message
    } else if (message.content_type === 'call_to_action' && message.content.text) {
        content = <MessageText text={message.content.text} />;
    } else if (message.content_type === 'text' && message.content) {
        content = <MessageText text={message.content} />;
    }

    return (
        content && (
            <div className="guest-inbox-app__message-list-item__message__content__container">
                <div className="guest-inbox-app__message-list-item__message__content">
                    {content}
                    {prompt}
                </div>
            </div>
        )
    );
}

export default class Message extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showTime: false,
            sendingInlineQuestionResponse: null,
        };
    }

    componentDidMount() {
        this.calcShowTime();
    }

    componentDidUpdate = (prevProps) => {
        const { last } = this.props;
        if (last !== prevProps.last) {
            this.calcShowTime();
        }
    };

    getCurrentAssessment() {
        const { message } = this.props;
        return _.get(message, `content.messaging_form.assessment`);
    }

    getSavedAssessmentAnswers() {
        return _.get(this.props, 'message.content.messaging_form.saved_assessment_answers');
    }

    shouldShowAssessmentCTABubble() {
        if (this.isInlineYesNoPromptQuestion()) {
            return (
                _.get(this.getSavedAssessmentAnswers(), `${this.getInlineYesNoPromptQuestionUuid()}.answer`) === 'yes'
            );
        }

        return true;
    }

    getInlineYesNoPromptQuestionUuid() {
        const { message } = this.props;
        return _.get(message, 'content.messaging_form.yes_no_prompt_question');
    }

    isInlineYesNoPromptQuestion() {
        return this.isCallToActionType() && _.isString(this.getInlineYesNoPromptQuestionUuid());
    }

    getSavedInlineYesNoPromptAnswer() {
        if (this.isInlineYesNoPromptQuestion()) {
            return _.get(this.getSavedAssessmentAnswers(), this.getInlineYesNoPromptQuestionUuid());
        }
    }

    isCallToActionType() {
        const { message } = this.props;
        return message.content_type === 'call_to_action';
    }

    getAssessmentId() {
        const { message } = this.props;

        if (message.content_type === 'call_to_action' && _.get(message, 'content.action_type') === 'messaging_form') {
            return _.get(message, 'content.messaging_form.assessment_id');
        }
    }

    isLoadingAssessmentData() {
        if (_.isNil(this.getAssessmentId())) {
            return false;
        }

        return _.isNil(this.getCurrentAssessment()) || _.isNil(this.getSavedInlineYesNoPromptAnswer());
    }

    calcShowTime = () => {
        const { message, previousMessage, last } = this.props;
        let showTime = this.state.showTime;

        if (!previousMessage) {
            showTime = true;
        } else if (last) {
            showTime = false;
        } else {
            const secondsDiff = getTimeDiff(previousMessage.created_date, message.created_date);
            showTime = secondsDiff > 300;
        }

        this.setState({
            showTime: showTime,
        });
    };

    getInlineYesNoPrompt() {
        const { recordAssessmentAnswers, pharmacy, message } = this.props;
        const { sendingInlineQuestionResponse } = this.state;
        const yesNoPromptQuestionUuid = this.getInlineYesNoPromptQuestionUuid();

        if (!_.isNil(yesNoPromptQuestionUuid)) {
            const answer = this.getSavedInlineYesNoPromptAnswer();
            const saveStatus = !_.isNil(answer)
                ? 'saved'
                : _.isNil(sendingInlineQuestionResponse)
                ? undefined
                : 'saving';

            return (
                <div
                    className="prompt-wrapper"
                    style={{
                        margin: '10px 0 5px 0',
                        pointerEvents: saveStatus === 'saved' ? 'none' : null,
                    }}
                >
                    <InlineYesNoQuestionPrompt
                        value={_.isNil(answer) ? sendingInlineQuestionResponse : answer.answer}
                        saveStatus={saveStatus}
                        updateAnswer={(v) => {
                            this.setState({ sendingInlineQuestionResponse: v });
                            recordAssessmentAnswers(
                                pharmacy.pharmacyId,
                                pharmacy.locationId,
                                message.inbox_message_id,
                                {
                                    [yesNoPromptQuestionUuid]: v,
                                }
                            );
                        }}
                    />
                    {_.isNil(answer) ? null : (
                        <div className="response-note">You answered {moment.utc(answer.created_date).fromNow()}</div>
                    )}
                </div>
            );
        }
        return null;
    }

    render() {
        // NOTE: might be causing more work than needed by doing all date formatting on every render refresh
        const { showTime } = this.state;
        const {
            message,
            user,
            last,
            pharmacy,
            activeLocationCountryCode,
            recordAssessmentAnswers,
            fetchAvailableSlots,
            fetchCalendar,
            fetchReservationSession,
            createReservation,
            checkInReservation,
            removeUserFromAllWaitlists,
            updateWaitlistForUser,
            conversation,
            createMessage,
        } = this.props;
        const isPatient = message.inbox_user_id === user.userId;
        const relativeTime = formatUTCToRelative({
            inputTimeString: message.created_date,
            countryCode: activeLocationCountryCode,
        });
        const isCallToActionContentType = message.content_type === 'call_to_action';

        return (
            <Fragment>
                {showTime && <div className="guest-inbox-app__message-list-item__time">{relativeTime}</div>}
                <li className={`guest-inbox-app__message-list-item ${isPatient ? 'patient' : ''}`}>
                    <div className="guest-inbox-app__message-list-item__message">
                        <MessageContent message={message} prompt={this.getInlineYesNoPrompt()} />

                        {isCallToActionContentType && this.shouldShowAssessmentCTABubble() && (
                            <div className="guest-inbox-app__message-list-item__cta-wrapper">
                                <MessageCTACard
                                    message={message}
                                    user={user}
                                    pharmacyId={pharmacy.pharmacyId}
                                    locationId={pharmacy.locationId}
                                    assessment={this.getCurrentAssessment()}
                                    savedAssessmentAnswers={this.getSavedAssessmentAnswers()}
                                    recordAssessmentAnswers={recordAssessmentAnswers}
                                    removeUserFromAllWaitlists={removeUserFromAllWaitlists}
                                    updateWaitlistForUser={updateWaitlistForUser}
                                    fetchAvailableSlots={fetchAvailableSlots}
                                    fetchCalendar={fetchCalendar}
                                    fetchReservationSession={fetchReservationSession}
                                    createReservation={createReservation}
                                    checkInReservation={checkInReservation}
                                    conversation={conversation}
                                    createMessage={createMessage}
                                    allMessages={this.props.allMessages}
                                />
                            </div>
                        )}

                        {last && (
                            <div className="guest-inbox-app__message-list-item__message__time">{relativeTime}</div>
                        )}
                    </div>
                </li>
            </Fragment>
        );
    }
}
