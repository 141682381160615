export const messageType = {
	RESET_MESSAGE_STORE: 'RESET_MESSAGE_STORE',
	GET_MESSAGES_REQUEST: 'GET_MESSAGES_REQUEST',
	GET_MESSAGES_SUCCESS: 'GET_MESSAGES_SUCCESS',
	GET_MESSAGES_FAILURE: 'GET_MESSAGES_FAILURE',
	CREATE_MESSAGE_REQUEST: 'CREATE_MESSAGE_REQUEST',
	CREATE_MESSAGE_SUCCESS: 'CREATE_MESSAGE_SUCCESS',
	CREATE_MESSAGE_FAILURE: 'CREATE_MESSAGE_FAILURE',
	CREATE_ATTACHMENT_REQUEST: 'CREATE_ATTACHMENT_REQUEST',
	CREATE_ATTACHMENT_SUCCESS: 'CREATE_ATTACHMENT_SUCCESS',
	CREATE_ATTACHMENT_FAILURE: 'CREATE_ATTACHMENT_FAILURE',
	GET_LATEST_MESSAGE_REQUEST: 'GET_LATEST_MESSAGE_REQUEST',
	GET_LATEST_MESSAGE_SUCCESS: 'GET_LATEST_MESSAGE_SUCCESS',
	GET_LATEST_MESSAGE_FAILURE: 'GET_LATEST_MESSAGE_FAILURE',
	UPDATE_MESSAGE_READ_STATUS_REQUEST: 'UPDATE_MESSAGE_READ_STATUS_REQUEST',
	UPDATE_MESSAGE_READ_STATUS_SUCCESS: 'UPDATE_MESSAGE_READ_STATUS_SUCCESS',
	UPDATE_MESSAGE_READ_STATUS_FAILURE: 'UPDATE_MESSAGE_READ_STATUS_FAILURE',
	RECORD_ASSESSMENT_ANSWERS_REQUEST: 'RECORD_ASSESSMENT_ANSWERS_REQUEST',
	RECORD_ASSESSMENT_ANSWERS_SUCCESS: 'RECORD_ASSESSMENT_ANSWERS_SUCCESS',
	RECORD_ASSESSMENT_ANSWERS_FAILURE: 'RECORD_ASSESSMENT_ANSWERS_FAILURE',
	
	FETCH_CALENDAR_REQUEST: 'FETCH_CALENDAR_REQUEST',
	FETCH_CALENDAR_SUCCESS: 'FETCH_CALENDAR_SUCCESS',
	FETCH_CALENDAR_FAILURE: 'FETCH_CALENDAR_FAILURE',
	
	FETCH_RESERVATION_SESSION_REQUEST: 'FETCH_RESERVATION_SESSION_REQUEST',
	FETCH_RESERVATION_SESSION_SUCCESS: 'FETCH_RESERVATION_SESSION_SUCCESS',
	FETCH_RESERVATION_SESSION_FAILURE: 'FETCH_RESERVATION_SESSION_FAILURE',
	
	FETCH_AVAILABLE_SLOTS_REQUEST: 'FETCH_AVAILABLE_SLOTS_REQUEST',
	FETCH_AVAILABLE_SLOTS_SUCCESS: 'FETCH_AVAILABLE_SLOTS_SUCCESS',
	FETCH_AVAILABLE_SLOTS_FAILURE: 'FETCH_AVAILABLE_SLOTS_FAILURE',
	
	CREATE_RESERVATION_REQUEST: 'CREATE_RESERVATION_REQUEST',
	CREATE_RESERVATION_SUCCESS: 'CREATE_RESERVATION_SUCCESS',
	CREATE_RESERVATION_FAILURE: 'CREATE_RESERVATION_FAILURE',
	
	CHECKIN_RESERVATION_REQUEST: 'CHECKIN_RESERVATION_REQUEST',
	CHECKIN_RESERVATION_SUCCESS: 'CHECKIN_RESERVATION_SUCCESS',
	CHECKIN_RESERVATION_FAILURE: 'CHECKIN_RESERVATION_FAILURE',
};

export default messageType;
