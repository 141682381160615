import Snowplow, { GlobalSiteContext, EventReferenceContext, InboxMessageContext } from '../snowplow';
import { pharmacyType } from './Pharmacy';
import { userType } from './User';
import { messageType } from './Message';
import { conversationType } from './Conversation';
import { parseQueryString } from '../utils/queryString';

const consentTypeMap = {
    message_consent: 'Message Authorization',
    privacy_consent: 'Privacy Consent',
    terms_conditions_consent: 'Terms & Conditions',
};

const snowplowMiddleware = (store) => (next) => (action) => {
    if (action.type === userType.SET_USER_ID) {
        Snowplow.setUserId(action.userId);
    } else if (action.type === pharmacyType.SET_PHARMACY_ID) {
    } else if (action.type === userType.GET_USER_PHARMACY_SUCCESS) {
        const queryStringDict = parseQueryString();
        const spParentEventRef = queryStringDict.er || null;
        Snowplow.clearGlobalContexts();
        Snowplow.addGlobalContexts([
            function () {
                return [
                    new GlobalSiteContext().setPharmacyId(action.pharmacyId).setLocationId(action.locationId).build(),
                    new EventReferenceContext().setParentEventRef(spParentEventRef).build(),
                ];
            },
        ]);
    } else if (action.type === userType.LOGIN_SUCCESS) {
        Snowplow.structEvent('Login', 'patient-login', null, 'dob', 'success');
    } else if (action.type === userType.LOGIN_FAILURE) {
        Snowplow.structEvent('Login', 'patient-login', null, 'dob', 'failure');
    } else if (action.type === userType.CONSENT_SUCCESS) {
        let createdConsentTypes = [];
        for (let type of action.consentTypes) {
            if (consentTypeMap[type]) {
                createdConsentTypes.push(consentTypeMap[type]);
            }
        }

        Snowplow.structEvent('Consent', 'patient-set-consent', null, '3-consent-form');
    } else if (action.type === conversationType.SET_ACTIVE_CONVERSATION) {
        let conversation = action.activeConversation;

        Snowplow.structEvent('Conversation', 'patient-viewed-conversation', [
            new InboxMessageContext()
                .setConversationId(conversation.inbox_conversation_id)
                .setPatientInboxUserId(conversation.patient_inbox_user_id)
                .build(),
        ]);
    } else if (action.type === messageType.CREATE_MESSAGE_SUCCESS) {
        const { conversation } = store.getState();

        Snowplow.structEvent(
            'Message',
            'patient-create-message',
            [
                new InboxMessageContext()
                    .setConversationId(conversation.activeConversationId)
                    .setMessageId(action.message.message_id)
                    .setPatientInboxUserId(conversation.activeConversation.patient_inbox_user_id)
                    .build(),
            ],
            'text'
        );
    } else if (action.type === messageType.CREATE_ATTACHMENT_SUCCESS) {
        const { conversation } = store.getState();

        Snowplow.structEvent(
            'Message',
            'patient-create-message',
            [
                new InboxMessageContext()
                    .setConversationId(conversation.activeConversationId)
                    .setMessageId(action.attachment.message_id)
                    .setPatientInboxUserId(conversation.activeConversation.patient_inbox_user_id)
                    .build(),
            ],
            'attachment'
        );
    } else if (action.type === messageType.UPDATE_MESSAGE_READ_STATUS_SUCCESS) {
        const { message } = store.getState();
        const { messages } = message;
        let updatedMessage = null;

        for (let i = 0; i < messages.length; i++) {
            if (messages[i].inbox_message_id === action.messageId) {
                updatedMessage = messages[i];
                break;
            }
        }

        if (updatedMessage) {
            Snowplow.structEvent('Message', 'patient-viewed-message', [
                new InboxMessageContext()
                    .setConversationId(updatedMessage.inbox_conversation_id)
                    .setMessageId(updatedMessage.inbox_message_id)
                    .build(),
            ]);
        }
    }

    next(action);
};

export default snowplowMiddleware;
