import React, { Component } from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import axios from 'axios';
import { connect } from 'react-redux';
import _ from 'lodash';
import { pharmacyAction } from '../redux/Pharmacy';
import { userAction } from '../redux/User';
import OverlayCircularProgress from './OverlayCircularProgress';
import './Inbox.css';
import Login from './Login';
import Conversation from './Conversation';
import Consent from './Consent';
import { parseQueryString } from '../utils/queryString';
import { getLocationData } from '../utils/helper';

const theme = createMuiTheme({
    palette: {
        primary: { main: '#3793ff' }, // blue
        // secondary: { main: '#11cb5f' }, // This is just green.A700 as hex.
    },
    // overrides: {
    // MuiButton: {
    // text: {
    // boxShadow: 'none'
    // }
    // }
    // },
    // typography: { useNextVariants: true },
});

const InboxNoUser = () => {
    return <div>No user id provided</div>;
};

const InboxLoading = () => {
    return <OverlayCircularProgress show />;
};

export class TplWrap extends Component {
    constructor(props) {
        super(props);
        this.state = {
            height: null,
            maxHeight: null,
        };

        this.setStyle = this.setStyle.bind(this);
    }

    componentDidMount() {
        this.setStyle();
        window.addEventListener('resize', this.setStyle);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.setStyle);
    }

    setStyle() {
        const windowWidth = window.innerWidth;
        if (windowWidth <= 720) {
            this.setState({
                height: `${window.innerHeight}px`,
                maxHeight: `${window.innerHeight}px`,
            });
        }
    }

    render() {
        const { height, maxHeight } = this.state;
        let style = {};
        if (height) {
            style = {
                height,
                maxHeight,
            };
        }

        return (
            <MuiThemeProvider theme={theme}>
                <div className="guest-inbox-app" style={style}>
                    {this.props.children}
                </div>
            </MuiThemeProvider>
        );
    }
}

class Inbox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pharmacyDisplayName: '',
            pharmacyLocationPhone: null,
            unauthorizedErrorCount: 0,
        };

        // Axios defaults
        const protocol = window.ENV_CONFIG.environ !== 'local' ? 'https://' : 'http://';
        axios.defaults.baseURL = `${protocol}${window.ENV_CONFIG.apiWebBase}`;
        axios.defaults.withCredentials = true;
        let that = this;
        axios.interceptors.response.use(
            function (response) {
                return response;
            },
            function (error) {
                // Handle 403 - unauthorized
                if (error.response.status === 403) {
                    // ignore the first 403 if we are loading messages since our session for these isn't immediately consistent
                    // therefore, we will only logout users after the 2nd failure if this endpoint returns a 403
                    if (_.endsWith(error.response.request.responseURL, '/messages')) {
                        if (that.state.unauthorizedErrorCount > 0) {
                            that.props.logoutUser();
                        } else {
                            that.setState({ unauthorizedErrorCount: ++that.state.unauthorizedErrorCount });
                        }
                    } else {
                        that.props.logoutUser();
                    }
                }
                return Promise.reject(error);
            }
        );
    }

    componentDidMount = () => {
        const queryStringDict = parseQueryString();
        const userId = queryStringDict.userId || null;

        this.props.setUserId(userId);
        this.props.getUserPharmacyData(userId);
    };

    loadReservations() {
        const { user, getReservationsForUser } = this.props;
        const { userPharmacyData, userId, authenticated } = user;
        const { pharmacyId, locationId } = userPharmacyData;

        if (authenticated && _.isString(pharmacyId) && _.isString(locationId) && _.isString(userId)) {
            if (
                _.isNil(_.get(user, 'fetchingReservations.response')) &&
                _.isNil(_.get(user, 'fetchingReservations.error'))
            ) {
                getReservationsForUser(pharmacyId, locationId, userId, 'ANY');
            }
        }
    }

    loadWaitlists() {
        const { user, getWaitlistsForUser } = this.props;
        const { userPharmacyData, userId, authenticated } = user;
        const { pharmacyId, locationId } = userPharmacyData;

        if (authenticated && _.isString(pharmacyId) && _.isString(locationId) && _.isString(userId)) {
            if (_.isNil(_.get(user, 'fetchingWaitlists.response')) && _.isNil(_.get(user, 'fetchingWaitlists.error'))) {
                getWaitlistsForUser(pharmacyId, locationId, userId);
            }
        }
    }

    componentDidUpdate = (prevProps) => {
        const { pharmacyId, locationId, pharmacyData } = this.props.pharmacy;
        const { userId } = this.props.user;

        if (pharmacyId && pharmacyId !== prevProps.pharmacy.pharmacyId) {
            this.props.getPharmacyData();
        }

        if (userId && userId !== prevProps.user.userId) {
            this.props.getUserData();
        }

        if (
            locationId &&
            Object.keys(pharmacyData).length > 0 &&
            Object.keys(prevProps.pharmacy.pharmacyData).length === 0
        ) {
            this.determinePharmacyDisplayName();
            this.determinePharmacyLocationPhone();
        }

        this.loadReservations();
        this.loadWaitlists();
    };

    determinePharmacyLocationPhone = () => {
        const { user, pharmacy } = this.props;
        const userPharmacyId = user.userPharmacyData.pharmacyId;
        const userLocationId = user.userPharmacyData.locationId;
        const pharmacyLocations = pharmacy.pharmacyData.location;

        if (userPharmacyId === pharmacy.pharmacyId) {
            for (let i = 0; i < pharmacyLocations.length; i++) {
                const location = pharmacyLocations[i];
                if (location.id === userLocationId) {
                    this.setState({
                        pharmacyLocationPhone: location.phone,
                    });
                }
            }
        }
    };

    determinePromptUserForConsent = () => {
        const {
            prompt_message_consent,
            prompt_privacy_consent,
            prompt_pharmacy_privacy_policy_consent,
            prompt_terms_conditions_consent,
        } = this.props.user.userData;

        return (
            prompt_message_consent ||
            prompt_privacy_consent ||
            prompt_pharmacy_privacy_policy_consent ||
            prompt_terms_conditions_consent
        );
    };

    determinePharmacyDisplayName = () => {
        const { locationId, pharmacyData } = this.props.pharmacy;

        if (locationId && pharmacyData) {
            const locationData = getLocationData(locationId, pharmacyData);
            if (locationData) {
                if (locationData.service.Inbox.displayLocationNameInNotif === true) {
                    if (locationData.service.Inbox.overrideLocationNameInNotif.length > 0) {
                        this.setState({
                            pharmacyDisplayName: locationData.service.Inbox.overrideLocationNameInNotif,
                        });
                    } else {
                        this.setState({
                            pharmacyDisplayName: locationData.displayName,
                        });
                    }
                } else {
                    this.setState({
                        pharmacyDisplayName: pharmacyData.name,
                    });
                }
            }
        }
    };

    render() {
        const { pharmacy, user } = this.props;
        // TODO rethink how to determine loading - will get stuck if pharmacy data fails
        const loading =
            pharmacy.loading ||
            user.loading ||
            user.loadingConsentTypes ||
            !Object.keys(pharmacy.pharmacyData).length > 0;
        const isAuthenticated = user.authenticated && user.userData;
        const { pharmacyDisplayName, pharmacyLocationPhone } = this.state;
        const promptUserForConsent = this.determinePromptUserForConsent();

        if (loading) {
            return <InboxLoading />;
        } else if (!user.userId) {
            return <InboxNoUser />;
        } else if (isAuthenticated) {
            if (promptUserForConsent) {
                return <Consent pharmacyName={pharmacy.pharmacyData.name} />;
            } else {
                return <Conversation />;
            }
        }

        // Default to login
        return (
            <Login
                pharmacyDisplayName={pharmacyDisplayName}
                userDetails={user.userPharmacyData}
                pharmacyLocationPhone={pharmacyLocationPhone}
                onLogin={() => this.setState({ unauthorizedErrorCount: 0 })}
            />
        );
    }
}

function mapStateToProps(state) {
    const { pharmacy, user } = state;
    return {
        pharmacy,
        user,
    };
}

const bindActionsToDispatch = (dispatch) => ({
    setPharmacyId: (pharmacyId) => dispatch(pharmacyAction.setPharmacyId(pharmacyId)),
    setLocationId: (locationId) => dispatch(pharmacyAction.setLocationId(locationId)),
    getPharmacyData: () => dispatch(pharmacyAction.getPharmacyData()),
    setUserId: (userId) => dispatch(userAction.setUserId(userId)),
    getUserData: () => dispatch(userAction.getUserData()),
    getUserPharmacyData: (userId) => dispatch(userAction.getUserPharmacyData(userId)),
    logoutUser: () => dispatch(userAction.logout()),
    getReservationsForUser: (pharmacyId, locationId, userId, status) =>
        dispatch(userAction.getReservationsForUser(pharmacyId, locationId, userId, status)),
    getWaitlistsForUser: (pharmacyId, locationId, userId) =>
        dispatch(userAction.getWaitlistsForUser(pharmacyId, locationId, userId)),
});

export default connect(mapStateToProps, bindActionsToDispatch)(Inbox);
