import moment from 'moment/moment';
import _ from 'lodash';

export const stringToHslColor = (str, s, l) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    const h = hash % 360;
    return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
};

export const getDateInputFormatByCountryCode = ({ countryCode = 'US' }) => {
    if (countryCode === 'GB') {
        return 'DD/MM/YYYY';
    }

    return 'MM/DD/YYYY';
};

export const getAbbreviatedDateInputFormatByCountryCode = ({ countryCode = 'US' }) => {
    if (countryCode === 'GB') {
        return 'D MMM YYYY';
    }

    return 'MMM D, YYYY';
};

export const formatRawDate = (rawDate, inboundFormat = 'YYYY-MM-DD', outboundFormat = 'MM/DD/YYYY') => {
    const date = moment(rawDate, inboundFormat);
    if (date) {
        return date.format(outboundFormat);
    }
    return null;
};

export const makePossessive = (patientName) => {
    return patientName[patientName.length - 1] === "s" ? patientName + "'" : patientName + "'s";
}

export const formatRawPhone = (rawPhone, countryCode = 'US') => {
    const phoneLength = _.size(rawPhone);
    if (phoneLength < 10) {
        return null;
    }

    if (countryCode === 'GB') {
        const gbRegexPattern = phoneLength > 10 ? /^(\d{5})(\d{6})$/ : /^(\d{4})(\d{6})$/;
        const phoneSections = rawPhone.match(gbRegexPattern);
        return phoneSections && `${phoneSections[1]} ${phoneSections[2]}`;
    }

    const usRegexPattern = /^(\d{3})(\d{3})(\d{4})$/
    const phoneSections = rawPhone.match(usRegexPattern);
    return phoneSections && `(${phoneSections[1]}) ${phoneSections[2]}-${phoneSections[3]}`;
};

export const toDigits = (numberString) => {
    return numberString.replace(/\D+/g, '');
};

export const isEmptyString = (string) => {
    return (!string || string.length === 0);
};

export const getLocationData = (locationId, pharmacyData) => {
    const pharmacyLocations = _.get(pharmacyData, 'location', []);

    for (let i = 0; i < pharmacyLocations.length; i++) {
        if (pharmacyLocations[i].id === locationId) {
            return pharmacyLocations[i];
        }
    }

    return null;
};