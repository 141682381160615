import React, { Component, Fragment } from 'react';
import IconButton from '@material-ui/core/IconButton';
import SendIcon from '@material-ui/icons/Send';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import './Message.css';
import { isEmptyString, formatRawPhone } from '../utils/helper';
import ImageUpload from './ImageUpload';
import ImageUploadPreview from './ImageUploadPreview';
import './ImageUploadPreview.css';

export default class MessageInput extends Component {
	constructor(props) {
		super(props);
		this.state = {
			content: '',
			pharmacyPhone: null,
			imageUrl: null,
			selectedFile: null
		};
	}

	componentDidMount = () => {
		this.setState({
			pharmacyPhone: this.getPharmacyPhone()
		});
	};

	componentDidUpdate = prevProps => {
		const { sending } = this.props;
		if (prevProps.sending && !sending) {
			this.setState({
				content: '',
				selectedFile: null,
				imageUrl: null
			});
		}
	};

	handleSubmit = () => {
		const { content } = this.state;
		// Validate inputs
		if (!isEmptyString(content)) {
			// Validation passed
			this.props.createMessage(content);
		}
	};

	handleChange = event => {
		const { name, value } = event.target;
		this.setState({ [name]: value });
	};

	getPharmacyPhone = () => {
		const { user, pharmacy } = this.props;
		const userPharmacyId = user.userData.pharmacy_id;
		const userLocationId = user.userData.location_id;
		const pharmacyLocations = pharmacy.pharmacyData.location;

		if (userPharmacyId === pharmacy.pharmacyId) {
			for (let i = 0; i < pharmacyLocations.length; i++) {
				const location = pharmacyLocations[i];
				if (location.id === userLocationId) {
					return location.phone;
				}
			}
			console.warn("Didn't find matching location_id in pharmacy data");
			return null;
		}

		console.warn("Query string pharmacy_id doesn't match user pharmacy_id");
		return null;
	};

	handleAttachment = (imageUrl, selectedFile) => {
		this.setState({
			imageUrl,
			selectedFile
		});
	};

	onImageSubmit = () => {
		const { selectedFile } = this.state;
		// Validate inputs
		if (selectedFile) {
			// Validation passed
			this.props.createAttachment(selectedFile);
		}
	};

	render() {
		const { content, pharmacyPhone } = this.state;
		const { sending, activeLocationCountryCode } = this.props;

		return (
			<div>
				<div className="conversation-message__warning">
					Your pharmacy typically responds within one business day.{' '}
					<br />
					{pharmacyPhone ? (
						<span>
							If this is urgent, please call the pharmacy at{' '}
							<a href={`tel:${pharmacyPhone}`}>
								{formatRawPhone(pharmacyPhone, activeLocationCountryCode)}
							</a>
						</span>
					) : (
						'If this is urgent, please call the pharmacy'
					)}
				</div>
				<div className="conversation-message-input">
					<div className="conversation-message-input__attach">
						<ImageUpload
							handleAttachment={this.handleAttachment}
							onImageChange={this.onImageSubmit}
							imageUrl={this.state.imageUrl}
							selectedFile={this.state.selectedFile}
						/>
					</div>
					{this.state.imageUrl ? (
						<ImageUploadPreview
							imageUrl={this.state.imageUrl}
							handleAttachment={this.handleAttachment}
							onImageSubmit={this.onImageSubmit}
							sending={sending}
						/>
					) : (
						<Fragment>
							<div className="conversation-message-input__content">
								<TextField
									type="text"
									accept="any"
									id="content"
									name="content"
									label="content"
									placeholder="Type your message ..."
									value={content}
									required
									fullWidth
									multiline
									rowsMax={10}
									InputLabelProps={{
										shrink: true,
										classes: {
											root: 'message-content-input-label'
										}
									}}
									InputProps={{
										classes: {
											root: 'message-content-input'
										},
										disableUnderline: true
									}}
									variant="standard"
									margin="none"
									onChange={this.handleChange}
								/>
							</div>
							<div className="conversation-message-input__submit">
								<IconButton
									aria-haspopup="true"
									color="inherit"
									onClick={this.handleSubmit}
									disabled={sending}
									className="conversation-message-input__send nav-icon-padding"
								>
									{sending ? (
										<CircularProgress
											size={20}
											className="conversation-message-input__progress"
										/>
									) : (
										<SendIcon
											style={{ color: '#757575' }}
										/>
									)}
								</IconButton>
							</div>
						</Fragment>
					)}
				</div>
			</div>
		);
	}
}
