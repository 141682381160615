import messageType from './type.js';
import messageService from './service.js';
import _ from 'lodash';

const resetMessageStore = () => {
    return {
        type: messageType.RESET_MESSAGE_STORE,
    };
};

const getMessages = () => {
    return function (dispatch, getState) {
        dispatch(request());
        const conversationId = getState().conversation.activeConversationId;

        return new Promise((resolve, reject) => {
            messageService.getMessages(conversationId).then(
                (messages) => {
                    dispatch(success(messages));
                    resolve(messages);
                },
                (error) => {
                    dispatch(failure(error.toString()));
                    reject(error);
                }
            );
        });
    };

    function request() {
        return { type: messageType.GET_MESSAGES_REQUEST };
    }
    function success(messages) {
        return { type: messageType.GET_MESSAGES_SUCCESS, messages };
    }
    function failure(error) {
        return { type: messageType.GET_MESSAGES_FAILURE, error };
    }
};

const createAttachment = (attachmentContent) => {
    return function (dispatch, getState) {
        dispatch(request());
        const { conversation, user } = getState();
        const conversationId = conversation.activeConversationId;
        const userId = user.userId;

        messageService.createAttachment(userId, conversationId, attachmentContent).then(
            (attachment) => {
                dispatch(success(attachment));
            },
            (error) => {
                dispatch(failure(error.toString()));
            }
        );
    };

    function request() {
        return { type: messageType.CREATE_ATTACHMENT_REQUEST };
    }
    function success(attachment) {
        return { type: messageType.CREATE_ATTACHMENT_SUCCESS, attachment };
    }
    function failure(error) {
        return { type: messageType.CREATE_ATTACHMENT_FAILURE, error };
    }
};

const createMessage = (messageContent) => {
    return async function (dispatch, getState) {
        dispatch(request());
        const { conversation, user } = getState();
        const conversationId = conversation.activeConversationId;
        const userId = user.userId;

        try {
            const message = await messageService.createMessage(conversationId, userId, messageContent);
            dispatch(success(message));
            return message;
        } catch (error) {
            dispatch(failure(error.toString()));
            throw error;
        }
    };

    function request() {
        return { type: messageType.CREATE_MESSAGE_REQUEST };
    }
    function success(message) {
        return { type: messageType.CREATE_MESSAGE_SUCCESS, message };
    }
    function failure(error) {
        return { type: messageType.CREATE_MESSAGE_FAILURE, error };
    }
};

const getLatestMessage = () => {
    return function (dispatch, getState) {
        dispatch(request());
        const { conversation } = getState();
        const conversationId = conversation.activeConversationId;

        messageService.getLatestMessage(conversationId).then(
            (latestMessage) => {
                dispatch(success(latestMessage));
            },
            (error) => {
                dispatch(failure(error.toString()));
            }
        );
    };

    function request() {
        return { type: messageType.GET_LATEST_MESSAGE_REQUEST };
    }
    function success(latestMessage) {
        return { type: messageType.GET_LATEST_MESSAGE_SUCCESS, latestMessage };
    }
    function failure(error) {
        return { type: messageType.GET_LATEST_MESSAGE_FAILURE, error };
    }
};

const updateMessageReadStatus = () => {
    return function (dispatch, getState) {
        dispatch(request());
        const { conversation, pharmacy } = getState();
        const pharmacyId = pharmacy.pharmacyId;
        const locationId = pharmacy.locationId;
        const conversationId = conversation.activeConversationId;

        messageService.updateMessageReadStatus(pharmacyId, locationId, conversationId).then(
            () => {
                dispatch(success(pharmacyId, locationId, conversationId));
            },
            (error) => {
                dispatch(failure(error));
            }
        );
    };

    function request() {
        return { type: messageType.UPDATE_MESSAGE_READ_STATUS_REQUEST };
    }
    function success(conversationId) {
        return {
            type: messageType.UPDATE_MESSAGE_READ_STATUS_SUCCESS,
            conversationId,
        };
    }
    function failure(error) {
        return { type: messageType.UPDATE_MESSAGE_READ_STATUS_FAILURE, error };
    }
};

const recordAssessmentAnswers = (pharmacyId, locationId, inboxMessageId, answers) => {
    return async function (dispatch, getState) {
        const { message } = getState();

        if (_.get(message, `recordingAssessmentAnswers`)) {
            return Promise.resolve();
        }

        dispatch(request(inboxMessageId));

        messageService.recordAssessmentAnswers(pharmacyId, locationId, inboxMessageId, answers).then(
            (answers) => {
                dispatch(success(inboxMessageId, answers));
                return Promise.resolve(answers);
            },
            (error) => {
                dispatch(failure(inboxMessageId, error.toString()));
                return Promise.reject(error);
            }
        );
    };

    function request(inboxMessageId) {
        return {
            type: messageType.RECORD_ASSESSMENT_ANSWERS_REQUEST,
            inboxMessageId,
        };
    }
    function success(inboxMessageId, answers) {
        return {
            type: messageType.RECORD_ASSESSMENT_ANSWERS_SUCCESS,
            answers,
            inboxMessageId,
        };
    }
    function failure(inboxMessageId, error) {
        return {
            type: messageType.RECORD_ASSESSMENT_ANSWERS_FAILURE,
            error,
            inboxMessageId,
        };
    }
};

function fetchCalendar(pharmacyId, locationId, calendarId) {
    return async function (dispatch, getState) {
        const { message } = getState();

        if (_.get(message, 'fetchingCalendar.loading')) {
            return Promise.resolve();
        }

        dispatch({
            type: messageType.FETCH_CALENDAR_REQUEST,
            payload: { pharmacyId, locationId, calendarId },
        });

        try {
            const response = await messageService.fetchCalendar(pharmacyId, locationId, calendarId);
            dispatch({
                type: messageType.FETCH_CALENDAR_SUCCESS,
                payload: { pharmacyId, locationId, calendarId, response },
            });

            return response;
        } catch (error) {
            dispatch({
                type: messageType.FETCH_CALENDAR_FAILURE,
                payload: { pharmacyId, locationId, calendarId, error },
            });
        }
    };
}

function fetchReservationSession(pharmacyId, locationId, sessionId) {
    return async function (dispatch, getState) {
        const { message } = getState();

        if (_.get(message, 'fetchingReservationSession.loading')) {
            return Promise.resolve();
        }

        dispatch({
            type: messageType.FETCH_RESERVATION_SESSION_REQUEST,
            payload: { pharmacyId, locationId, sessionId },
        });

        try {
            const response = await messageService.fetchReservationSession(pharmacyId, locationId, sessionId);
            dispatch({
                type: messageType.FETCH_RESERVATION_SESSION_SUCCESS,
                payload: { pharmacyId, locationId, sessionId, response },
            });

            return response;
        } catch (error) {
            dispatch({
                type: messageType.FETCH_RESERVATION_SESSION_FAILURE,
                payload: { pharmacyId, locationId, sessionId, error },
            });
        }
    };
}

function fetchAvailableSlots(pharmacyId, locationId, calendarId, reservationSessionId) {
    return async function (dispatch, getState) {
        const { message } = getState();

        if (_.get(message, 'fetchingAvailableSlots.loading')) {
            return Promise.resolve();
        }

        dispatch({
            type: messageType.FETCH_AVAILABLE_SLOTS_REQUEST,
            payload: { pharmacyId, locationId, calendarId, reservationSessionId },
        });

        try {
            const response = await messageService.fetchAvailableSlots(
                pharmacyId,
                locationId,
                calendarId,
                reservationSessionId
            );
            dispatch({
                type: messageType.FETCH_AVAILABLE_SLOTS_SUCCESS,
                payload: {
                    pharmacyId,
                    locationId,
                    calendarId,
                    reservationSessionId,
                    response,
                },
            });

            return response;
        } catch (error) {
            dispatch({
                type: messageType.FETCH_AVAILABLE_SLOTS_FAILURE,
                payload: {
                    pharmacyId,
                    locationId,
                    calendarId,
                    reservationSessionId,
                    error,
                },
            });
        }
    };
}

function createReservation(pharmacyId, locationId, calendarId, reservation) {
    return async function (dispatch, getState) {
        const { message } = getState();

        if (_.get(message, 'creatingReservation.loading')) {
            return Promise.resolve();
        }

        dispatch({
            type: messageType.CREATE_RESERVATION_REQUEST,
            payload: { pharmacyId, locationId, calendarId, reservation },
        });

        try {
            const response = await messageService.createReservation(pharmacyId, locationId, calendarId, reservation);
            dispatch({
                type: messageType.CREATE_RESERVATION_SUCCESS,
                payload: { pharmacyId, locationId, calendarId, reservation, response },
            });

            return response;
        } catch (error) {
            dispatch({
                type: messageType.CREATE_RESERVATION_FAILURE,
                payload: { pharmacyId, locationId, calendarId, reservation, error },
            });

            return Promise.reject(error);
        }
    };
}

function checkInReservation(pharmacyId, locationId, calendarId, reservationId) {
    return async function (dispatch, getState) {
        const { message } = getState();

        if (_.get(message, 'checkingInReservation.loading')) {
            return Promise.resolve();
        }

        dispatch({
            type: messageType.CHECKIN_RESERVATION_REQUEST,
            payload: { pharmacyId, locationId, calendarId, reservationId },
        });

        try {
            const response = await messageService.checkInReservation(pharmacyId, locationId, calendarId, reservationId);
            dispatch({
                type: messageType.CHECKIN_RESERVATION_SUCCESS,
                payload: {
                    pharmacyId,
                    locationId,
                    calendarId,
                    reservationId,
                    response,
                },
            });

            return response;
        } catch (error) {
            dispatch({
                type: messageType.CHECKIN_RESERVATION_FAILURE,
                payload: { pharmacyId, locationId, calendarId, reservationId, error },
            });

            return Promise.reject(error);
        }
    };
}

export const messageAction = {
    resetMessageStore,
    getMessages,
    createMessage,
    getLatestMessage,
    updateMessageReadStatus,
    createAttachment,
    recordAssessmentAnswers,
    fetchAvailableSlots,
    fetchCalendar,
    fetchReservationSession,
    createReservation,
    checkInReservation,
};

export default messageAction;
