import userType from "./type";

export const initialState = {
    authenticated: false,
    sending: false,
    loading: false,
    loadingConsentTypes: false,
    userId: null,
    pharmacyId: null,
    userData: {},
    consentTypes: {},
    userPharmacyData: {},
    loginSuccess: false,
    loginLoading: false,
    loginMessage: null,
    initialLoad: true,
    error: {},
    
    decliningWaitlist: {}
};

export const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case userType.RESET_USER_STORE:
            return {
                ...initialState,
                initialLoad: false,
                userId: state.userId, // Keep current userId even through reset
                userPharmacyData: state.userPharmacyData // Keep current userPharmacyData even through reset
            };
        case userType.SET_USER_ID:
            return {
                ...state,
                userId: action.userId
            };
        case userType.LOGOUT:
            return {
                ...state
            };
        case userType.LOGIN_REQUEST:
            return {
                ...state,
                loginLoading: true
            };
        case userType.LOGIN_SUCCESS:
            return {
                ...state,
                loginLoading: false,
                loginSuccess: true,
                loginMessage: null
            };
        case userType.LOGIN_FAILURE:
            return {
                ...state,
                loginLoading: false,
                loginSuccess: false,
                loginMessage: action.error.message,
                error: action.error
            };
        case userType.CONSENT_REQUEST:
            return {
                ...state,
                sending: true
            };
        case userType.CONSENT_SUCCESS:
            let userData = { ...state.userData };

            action.consentTypes.forEach(consentType => {
                const promptKey = `prompt_${consentType}`;
                userData[promptKey] = false;
                userData[consentType] = true;
            });

            return {
                ...state,
                sending: false,
                userData: {
                    ...userData
                }
            };

        case userType.GET_CONSENT_REQUEST:
            return {
                ...state,
                loadingConsentTypes: true,
            };

        case userType.GET_CONSENT_SUCCESS:
            const { message, privacyPolicy, termsConditions, pharmacyPrivacyPolicy} = action.data;

            return {
              ...state,
                loadingConsentTypes: false,
                consentTypes: {
                  message,
                  privacyPolicy,
                  termsConditions,
                  pharmacyPrivacyPolicy,
                }
            };

        case userType.GET_CONSENT_FAILURE:
            return {
                ...state,
                loadingConsentTypes: false,
                error: action.error
            };

        case userType.CONSENT_FAILURE:
            return {
                ...state,
                sending: false,
                error: action.error
            };

        case userType.GET_USER_REQUEST:
            return {
                ...state,
                loading: true
            };
        case userType.GET_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                authenticated: true,
                userData: action.userData
            };
        case userType.GET_USER_FAILURE:
            return {
                ...state,
                loading: false,
                userData: {},
                authenticated: false,
                error: action.error
            };
        case userType.GET_USER_PHARMACY_REQUEST:
            return {
                ...state
            };
        case userType.GET_USER_PHARMACY_SUCCESS:
            return {
                ...state,
                userPharmacyData: {
                    pharmacyId: action.pharmacyId,
                    locationId: action.locationId,
                    firstName: action.firstName
                }
            };
        case userType.GET_USER_PHARMACY_FAILURE:
            return {
                ...state,
                userPharmacyData: {},
                error: action.error
            };
            
        case userType.GET_RESERVATIONS_FOR_USER_REQUEST:
        case userType.GET_RESERVATIONS_FOR_USER_SUCCESS:
        case userType.GET_RESERVATIONS_FOR_USER_FAILURE:
            return {
                ...state,
                fetchingReservations: {
                    loading: action.type === userType.GET_RESERVATIONS_FOR_USER_REQUEST,
                    ...action.payload
                }
            }
            
        case userType.GET_WAITLISTS_FOR_USER_REQUEST:
        case userType.GET_WAITLISTS_FOR_USER_SUCCESS:
        case userType.GET_WAITLISTS_FOR_USER_FAILURE:
            return {
                ...state,
                fetchingWaitlists: {
                    loading: action.type === userType.GET_WAITLISTS_FOR_USER_REQUEST,
                    ...action.payload
                }
            }
            
        case userType.UPDATE_WAITLIST_FOR_USER_REQUEST:
        case userType.UPDATE_WAITLIST_FOR_USER_SUCCESS:
        case userType.UPDATE_WAITLIST_FOR_USER_FAILURE:
            return {
                ...state,
                decliningWaitlist: {
                    ...state.decliningWaitlist,
                    [action.payload.waitlistId]: {
                        loading: action.type === userType.DECLINE_WAITLIST_FOR_USER_REQUEST,
                        ...action.payload
                    }
                }
            }
            
        case userType.APPEND_NEW_RESERVATION:
            return {
                ...state,
                fetchingReservations: {
                    ...state.fetchingReservations,
                    response: {
                        ...state.fetchingReservations.response,
                        reservations: state.fetchingReservations.response.reservations.concat([action.payload.newReservation]),
                    }
                }
            }
            
        default:
            return state;
    }
};

export default userReducer;
