import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { conversationAction } from '../redux/Conversation';
import { messageAction } from '../redux/Message';
import { userAction } from '../redux/User';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Typography from '@material-ui/core/Typography';
import './Message.css';
import Snowplow from '../snowplow';
import Message from './Message';
import OverlayCircularProgress from './OverlayCircularProgress';
import MessageInput from './MessageInput';
import { pharmacySelectors } from '../redux/Pharmacy/selector';
class MessageList extends Component {
    constructor(props) {
        super(props);
        this.anchorEl = null;
    }

    componentDidMount = () => {
        // if this fails initially, try it one more time to account for the session not always immediately
        // having the current conversation id consistently written immediately
        this.props.getMessages().catch(() => {
            this.props.getMessages();
        });
        this.scrollToAnchor();

        Snowplow.pageView('Messages');
    };

    componentDidUpdate = (prevProps) => {
        const { message } = this.props;
        if (message.messages.length > prevProps.message.messages.length) {
            this.scrollToAnchor();
        }
        if (message.messages && message.messages.length > 0 && prevProps.message.messages) {
            if (message.messages.length !== prevProps.message.messages.length) {
                this.updateMessageReadStatus();
            }
        }
    };

    scrollToAnchor = () => {
        let that = this;
        setTimeout(function () {
            that.anchorEl.scrollIntoView(false);
        });
    };

    updateMessageReadStatus = () => {
        const { message, updateMessageReadStatus } = this.props;
        const messageList = message.messages;

        if (messageList.length > 0) {
            const lastMessage = messageList[messageList.length - 1];
            if (lastMessage.user_type === 'pharmacist' && !lastMessage.viewed) {
                const lastMessageId = lastMessage.inbox_message_id;
                updateMessageReadStatus(lastMessageId);
            }
        }
    };

    render() {
        const {
            conversation,
            user,
            message,
            pharmacy,
            createMessage,
            createAttachment,
            clearActiveConversation,
            activeLocationCountryCode,
            recordAssessmentAnswers,
            fetchCalendar,
            fetchReservationSession,
            fetchAvailableSlots,
            createReservation,
            checkInReservation,
            removeUserFromAllWaitlists,
            updateWaitlistForUser,
        } = this.props;

        const messages = message.messages;
        const loading = message.loading;
        const sending = message.sending;

        const messageComponents = messages.map((messageItem, index, messages) => (
            <Message
                key={messageItem.inbox_message_id}
                user={user}
                pharmacy={pharmacy}
                recordAssessmentAnswers={recordAssessmentAnswers}
                fetchCalendar={fetchCalendar}
                fetchReservationSession={fetchReservationSession}
                fetchAvailableSlots={fetchAvailableSlots}
                createReservation={createReservation}
                checkInReservation={checkInReservation}
                createMessage={createMessage}
                allMessages={messages}
                removeUserFromAllWaitlists={removeUserFromAllWaitlists}
                updateWaitlistForUser={updateWaitlistForUser}
                assessments={message.assessments}
                savedAssessmentAnswers={message.savedAssessmentAnswers}
                message={messageItem}
                previousMessage={index > 0 ? messages[index - 1] : null}
                last={index + 1 === message.length}
                activeLocationCountryCode={activeLocationCountryCode}
                conversation={conversation}
            />
        ));
        return (
            <div className="guest-inbox-app__message">
                <List
                    component="nav"
                    subheader={
                        <Fragment>
                            <Toolbar
                                disableGutters
                                style={{
                                    top: 0,
                                    position: 'sticky',
                                    zIndex: 1,
                                    backgroundColor: 'white',
                                }}
                            >
                                <IconButton
                                    aria-haspopup="true"
                                    color="inherit"
                                    onClick={clearActiveConversation}
                                    className="app-back-button nav-icon-padding"
                                >
                                    <ArrowBackIcon />
                                </IconButton>
                                <Typography variant="h5" color="inherit" className="header-nav-title-text">
                                    {conversation.subject}{' '}
                                </Typography>
                            </Toolbar>
                        </Fragment>
                    }
                    className="guest-inbox-app__message-header"
                ></List>
                <div className="guest-inbox-app__message-list-wrap">
                    <div className="guest-inbox-app__message-list">
                        {messageComponents}
                        <div
                            className="guest-inbox-app__message-list-anchor"
                            ref={(el) => {
                                this.anchorEl = el;
                            }}
                        />
                    </div>
                    <OverlayCircularProgress show={loading} />
                </div>
                <div className="guest-inbox-app__message-input">
                    <MessageInput
                        conversation={conversation}
                        user={user}
                        sending={sending}
                        pharmacy={pharmacy}
                        createMessage={createMessage}
                        createAttachment={createAttachment}
                        activeLocationCountryCode={activeLocationCountryCode}
                    />
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { user, conversation, message, pharmacy } = state;

    return {
        pharmacy,
        user,
        message,
        conversation: conversation.activeConversation,
        activeLocationCountryCode: pharmacySelectors.pharmacyActiveLocationCountryCodeSelector(state),
    };
}

const bindActionsToDispatch = (dispatch) => ({
    getMessages: () => dispatch(messageAction.getMessages()),
    createMessage: (messageContent) => dispatch(messageAction.createMessage(messageContent)),
    getLatestMessage: () => dispatch(messageAction.getLatestMessage()),
    updateMessageReadStatus: (conversationId) => dispatch(messageAction.updateMessageReadStatus(conversationId)),
    clearActiveConversation: () => dispatch(conversationAction.clearActiveConversation()),
    createAttachment: (attachmentContent) => dispatch(messageAction.createAttachment(attachmentContent)),
    recordAssessmentAnswers: (pharmacyId, locationId, inboxMessageId, answers) =>
        dispatch(messageAction.recordAssessmentAnswers(pharmacyId, locationId, inboxMessageId, answers)),
    fetchCalendar: (pharmacyId, locationId, calendarId) =>
        dispatch(messageAction.fetchCalendar(pharmacyId, locationId, calendarId)),
    fetchReservationSession: (pharmacyId, locationId, calendarId, sessionId) =>
        dispatch(messageAction.fetchReservationSession(pharmacyId, locationId, calendarId, sessionId)),
    fetchAvailableSlots: (pharmacyId, locationId, calendarId, reservationSessionId) =>
        dispatch(messageAction.fetchAvailableSlots(pharmacyId, locationId, calendarId, reservationSessionId)),
    createReservation: (pharmacyId, locationId, calendarId, reservation) =>
        dispatch(messageAction.createReservation(pharmacyId, locationId, calendarId, reservation)),
    checkInReservation: (pharmacyId, locationId, calendarId, reservationId) =>
        dispatch(messageAction.checkInReservation(pharmacyId, locationId, calendarId, reservationId)),
    updateWaitlistForUser: (pharmacyId, locationId, userId, waitlistId, status) =>
        dispatch(userAction.updateWaitlistForUser(pharmacyId, locationId, userId, waitlistId, status)),
});

export default connect(mapStateToProps, bindActionsToDispatch)(MessageList);
