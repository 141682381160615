
export const conversationType = {
    RESET_CONVERSATION_STORE: 'RESET_CONVERSATION_STORE',
    SET_ACTIVE_CONVERSATION: 'SET_ACTIVE_CONVERSATION',
    CLEAR_ACTIVE_CONVERSATION: 'CLEAR_ACTIVE_CONVERSATION',
    GET_CONVERSATIONS_REQUEST: 'GET_CONVERSATIONS_REQUEST',
    GET_CONVERSATIONS_SUCCESS: 'GET_CONVERSATIONS_SUCCESS',
    GET_CONVERSATIONS_FAILURE: 'GET_CONVERSATIONS_FAILURE'
};


export default conversationType;