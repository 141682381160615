import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import reducer from './redux/reducers';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import inboxMiddleware from './redux/inboxMiddleware';
import snowplowMiddleware from './redux/snowplowMiddleware';
import socketMiddleware from './redux/socketMiddleware';

const composeEnhancers = composeWithDevTools({
    // Specify custom devTools options
});

const logger = (store) => (next) => (action) => {
    if (process.env.NODE_ENV === 'development') {
        console.group(action.type);
        console.log('dispatching', action);
        const result = next(action);
        console.log('next state', store.getState());
        console.groupEnd();
        return result;
    }
    return next(action);
};

const store = createStore(
    reducer,
    composeEnhancers(applyMiddleware(logger, thunkMiddleware, inboxMiddleware, snowplowMiddleware, socketMiddleware))
);

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
