import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { CircularProgress, Typography, Button } from '@material-ui/core';
import Snowplow, { InboxMessageContext } from '../snowplow';
import moment from 'moment-timezone';
import { connect } from 'react-redux';

import {
    FullScreenStackedForm,
    areAllQuestionsAnswered,
    AppointmentSchedulerForm,
    SavingButton,
    ThirdPartySchedulerForm,
} from '@digitalpharmacist/messaging-forms';
import { getAppointmentMatchingMessage, getReservationById, hasUserDeclinedScheduling } from '../utils/appointmentUtil';
import { pharmacySelectors } from '../redux/Pharmacy/selector';

MessageCTACard.propTypes = {
    message: PropTypes.shape({
        content: PropTypes.shape({
            nav_to: PropTypes.shape({
                activity: PropTypes.string.isRequired,
                data: PropTypes.shape({
                    account_message: PropTypes.string,
                    content_summary: PropTypes.string,
                    content_title: PropTypes.string,
                    content_id: PropTypes.string,
                }).isRequired,
            }),
            action_type: PropTypes.string.isRequired,
            action_title: PropTypes.string.isRequired,
            action_text: PropTypes.string.isRequired,
            action_public_image_url: PropTypes.string,
        }).isRequired,
        content_type: PropTypes.oneOf(['text', 'attachment', 'call_to_action']),
        inbox_message_id: PropTypes.string.isRequired,
        inbox_conversation_id: PropTypes.string.isRequired,
    }).isRequired,
    pharmacyId: PropTypes.string.isRequired,
};

function getPatientPortalUrlWithEncodedParams({ pharmacyId, paramData }) {
    const paramsWithConfigId = {
        configId: pharmacyId,
        showBranding: 1,
        ...paramData,
    };

    const encodedParams = _.reduce(
        paramsWithConfigId,
        (result, val, key) => {
            const delimiter = result ? '&' : '?';
            // if the value is undefined or null we do not want to include it in the url, otherwise encode it
            return _.isNil(val) ? result : `${result}${delimiter}${key}=${encodeURIComponent(val)}`;
        },
        ''
    );

    return `https://${window.ENV_CONFIG.appDPBase}/portal/${encodedParams}`;
}

function MessageCTACard(props) {
    const [formDefinition, setFormDefinition] = useState();
    const [allAnswered, setAllAnswered] = useState();
    const [completedAtMillis, setCompletedAtMillis] = useState();
    const [calendar, setCalendar] = useState();
    const [appointmentLength, setAppointmentLength] = useState();
    const [canSchedule, setCanSchedule] = useState(null);

    const {
        message,
        user,
        pharmacyId,
        locationId,
        assessment,
        savedAssessmentAnswers,
        recordAssessmentAnswers,
        fetchAvailableSlots,
        fetchCalendar,
        createReservation,
        checkInReservation,
        updateWaitlistForUser,
        conversation,
        createMessage,
        fetchReservationSession,
        fullPepEnabled,
    } = props;
    const { content, inbox_message_id, inbox_conversation_id, content_type } = message;

    useEffect(() => {
        if (!_.isNil(assessment) && !_.isNil(savedAssessmentAnswers)) {
            const answers = {};
            _.forIn(savedAssessmentAnswers, ({ answer }, questionId) => (answers[questionId] = answer));

            const d = {
                questions: _.map(_.get(assessment, 'questions'), (q) => {
                    return {
                        id: q.patient_assessment_template_question_id,
                        type: q.question_type,
                        prompt: q.question_text,
                    };
                }),
                answers,
            };

            setFormDefinition(d);

            areAllQuestionsAnswered(d, answers).then(setAllAnswered);

            const maxCreatedDate = _.get(
                _.last(_.sortBy(_.values(savedAssessmentAnswers), ({ created_date }) => created_date)),
                'created_date'
            );
            if (!_.isNil(maxCreatedDate)) {
                setCompletedAtMillis(moment.utc(maxCreatedDate).valueOf());
            }
        }
    }, [assessment, savedAssessmentAnswers]);

    if (content_type !== 'call_to_action') {
        // safety short circuit at the component level
        return null;
    }

    const { action_title, action_text, action_public_image_url, nav_to, messaging_form } = content;

    if (!_.isNil(nav_to)) {
        const urlWithEncodedParams = getPatientPortalUrlWithEncodedParams({
            pharmacyId,
            paramData: nav_to.data,
        });

        return (
            <a
                href={urlWithEncodedParams}
                target="_blank"
                rel="noopener noreferrer"
                className="conversation-message-cta__container"
                onClick={() => {
                    Snowplow.structEvent('Message', 'patient-cta-clicked', [
                        new InboxMessageContext()
                            .setConversationId(inbox_conversation_id)
                            .setMessageId(inbox_message_id)
                            .build(),
                    ]);
                }}
            >
                <div className="conversation-message-cta__bubble">
                    <div className="conversation-message-cta__content">
                        {action_public_image_url && (
                            <img src={action_public_image_url} alt="CTA" height="40" width="40" />
                        )}

                        <div className="conversation-message-cta__text">
                            <Typography variant="subtitle1">{action_title}</Typography>
                            <Typography variant="body2">{action_text}</Typography>
                        </div>

                        <ArrowForwardIcon fontSize="small" style={{ alignSelf: 'center', fontSize: '16px' }} />
                    </div>
                </div>
            </a>
        );
    } else if (!_.isNil(messaging_form) && _.isString(_.get(messaging_form, 'assessment_id'))) {
        const isLoaded = !_.isNil(assessment) && !_.isNil(savedAssessmentAnswers) && !_.isNil(formDefinition);

        const filterNewAnswers = (answers) => {
            const newAnswers = {};
            _.forIn(answers, (answer, questionId) => {
                if (hasAnswerChanged(answer, questionId, savedAssessmentAnswers)) {
                    newAnswers[questionId] = answer;
                }
            });
            return newAnswers;
        };

        return (
            <span>
                <div className="conversation-message-cta__bubble">
                    <div
                        style={{
                            background: '#f7f7f7',
                            marginRight: '8px',
                        }}
                    >
                        <div className="assessment-cta-preprompt">
                            {_.get(message, 'content.messaging_form.assessment_cta_prompt', null)}
                        </div>

                        {!isLoaded ? (
                            <div className="assessment-loading">
                                <CircularProgress /> <span className="loading-text">Loading survey...</span>
                            </div>
                        ) : (
                            <span>
                                <FullScreenStackedForm
                                    key="form"
                                    title={action_title}
                                    formDefinition={formDefinition}
                                    allowEditingAnswers={true}
                                    onClose={(answers) => {
                                        // even if the patient did not complete the survey and click the submit button, we will
                                        // save any new answers regardless so we can get partial responses
                                        const newAnswers = filterNewAnswers(answers);
                                        if (!_.isEmpty(newAnswers)) {
                                            recordAssessmentAnswers(
                                                pharmacyId,
                                                locationId,
                                                message.inbox_message_id,
                                                newAnswers
                                            );
                                        }
                                    }}
                                    isRecorded={allAnswered}
                                    completedAtMillis={completedAtMillis}
                                    onSend={(answers) => {
                                        // only submit new answers...
                                        const newAnswers = filterNewAnswers(answers);
                                        if (!_.isEmpty(newAnswers)) {
                                            // Explicitly return this promise since it used used within this component to determine
                                            // when the request has completed.
                                            const promise = recordAssessmentAnswers(
                                                pharmacyId,
                                                locationId,
                                                message.inbox_message_id,
                                                newAnswers
                                            );

                                            promise
                                                .then(() => {
                                                    setAllAnswered(true);
                                                })
                                                .catch((error) => {
                                                    // Nothing to really do here since the component will show the error so just log it i guess
                                                    console.error(error);
                                                });

                                            return promise;
                                        }

                                        return Promise.resolve();
                                    }}
                                />
                                {!allAnswered ||
                                !_.isString(
                                    _.get(message, 'content.messaging_form.assessment_cta_completed_response')
                                ) ? null : (
                                    <div className="assessment-cta-response" style={{ marginTop: '10px' }}>
                                        {_.get(message, 'content.messaging_form.assessment_cta_completed_response', '')}
                                    </div>
                                )}
                            </span>
                        )}
                    </div>
                </div>
            </span>
        );
    } else if (!_.isNil(messaging_form) && _.get(messaging_form, 'is_checkin')) {
        const reservation_id = _.get(messaging_form, 'reservation_id');
        const reservation = getReservationById(user, reservation_id);

        return (
            <span>
                <div className="conversation-message-cta__bubble">
                    <div
                        style={{
                            background: '#f7f7f7',
                            marginRight: '8px',
                            minWidth: '240px',
                        }}
                    >
                        <SavingButton
                            label="Check in"
                            savingLabel="Checking in..."
                            savedLabel="You are now checked in"
                            primaryColor="#00B18F"
                            isInitiallySaved={!_.isNil(reservation.check_in_date)}
                            onSave={() => {
                                return checkInReservation(
                                    pharmacyId,
                                    locationId,
                                    _.get(reservation, 'calendar_id'),
                                    reservation_id
                                );
                            }}
                            afterSaveMessage="Please message the pharmacy below if you checked-in by mistake."
                            afterErrorMessage="Unable to check you in. Please make sure you are connected to the internet or try again later."
                        />
                    </div>
                </div>
            </span>
        );
    } else if (!_.isNil(messaging_form) && _.get(messaging_form, 'is_reservation_confirmation')) {
        const hasSentNoInsuranceMessage = !_.isNil(
            _.find(props.allMessages, ({ content }) => content === 'I do not have insurance.')
        );

        return (
            <span>
                <div className="conversation-message-cta__bubble">
                    <div
                        style={{
                            background: '#f7f7f7',
                            marginRight: '8px',
                            minWidth: '240px',
                        }}
                    >
                        <SavingButton
                            label="I don't have insurance"
                            savingLabel="Sending message..."
                            savedLabel="I don't have insurance"
                            primaryColor="#00B18F"
                            isInitiallySaved={hasSentNoInsuranceMessage}
                            onSave={() => {
                                return createMessage('I do not have insurance.');
                            }}
                            afterSaveMessage="Thanks for letting us know!"
                            afterErrorMessage="An error occurred. Please make sure you are connected to the internet or try again later."
                        />
                    </div>
                </div>
            </span>
        );
    } else if (
        !_.isNil(messaging_form) &&
        _.get(messaging_form, 'scheduling_type') === 'dp-clinical' &&
        _.isString(_.get(messaging_form, 'reservation_session_id'))
    ) {
        return (
            <span>
                <div className="conversation-message-cta__bubble">
                    <div
                        style={{
                            background: '#f7f7f7',
                            marginRight: '8px',
                        }}
                    >
                        <AppointmentSchedulerForm
                            primaryColor="#00B18F"
                            secondaryColor="#DF4851"
                            pastSchedulingBufferMinutes={60} // don't allow them to schedule appointments within the next hour
                            showNotInterested={!_.isEmpty(_.get(messaging_form, 'waitlist_ids'))}
                            notInterestedText={_.get(messaging_form, 'notInterestedText')}
                            getPreviouslyScheduledAppointment={() => {
                                const appointment = getAppointmentMatchingMessage(user, message.inbox_message_id);
                                if (!_.isNil(appointment)) {
                                    // convert from utc to localtime for display
                                    const [date, time] = moment
                                        .utc(appointment.start_date, 'YYYY-MM-DDTHH:mm:ss')
                                        .local()
                                        .format('YYYY-MM-DDTHH:mm:ss')
                                        .split('T');

                                    return { date, time };
                                }
                            }}
                            getPreviouslySaidNotInterested={() => {
                                return hasUserDeclinedScheduling(user, messaging_form.waitlist_ids);
                            }}
                            getPreviouslySaidContactMeNextTime={() => false}
                            fetchCalendar={async () => {
                                try {
                                    const session = await fetchReservationSession(
                                        pharmacyId,
                                        locationId,
                                        messaging_form.reservation_session_id
                                    );

                                    setAppointmentLength(session.expected_length);

                                    const promise = fetchCalendar(pharmacyId, locationId, session.calendar_id);

                                    promise.then((c) => setCalendar(c));

                                    return promise;
                                } catch (error) {
                                    return Promise.reject(error);
                                }
                            }}
                            fetchAvailableSlots={async () => {
                                const response = await fetchAvailableSlots(
                                    pharmacyId,
                                    locationId,
                                    calendar.calendar_id,
                                    messaging_form.reservation_session_id
                                );

                                setCanSchedule(response.can_schedule);

                                return response;
                            }}
                            getAreAllSlotsFull={() =>
                                !fullPepEnabled ? true : _.isNil(canSchedule) ? false : !canSchedule
                            }
                            getIsFromWaitlist={() => true}
                            onScheduleAppointment={async ({ date, time }) => {
                                const DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';

                                const start_date = moment.tz(`${date} ${time}`, DATE_FORMAT, calendar.timezone).utc();
                                const end_date = moment.utc(start_date).add(appointmentLength, 'minutes');

                                const reservation = {
                                    calendar_id: calendar.calendar_id,
                                    session_id: messaging_form.reservation_session_id,
                                    inbox_user_id: user.userId,
                                    inbox_message_id: message.inbox_message_id,
                                    pharmacy_id: pharmacyId,
                                    location_id: locationId,
                                    status: 'ACTIVE',
                                    label: messaging_form.appointment_label,
                                    start_date: start_date.format(DATE_FORMAT),
                                    end_date: end_date.format(DATE_FORMAT),
                                    created_by_user_type: 'patient',
                                    created_by_uuid: user.userId,
                                    inbox_conversation_id: conversation.inbox_conversation_id,
                                    confirmation_text: messaging_form.scheduled_appointment_text,
                                };

                                const created = await createReservation(
                                    pharmacyId,
                                    locationId,
                                    calendar.calendar_id,
                                    reservation
                                );

                                const waitlists = _.filter(
                                    _.get(user, 'fetchingWaitlists.response.waitlists'),
                                    ({ status }) => status !== 'DECLINED'
                                );
                                await Promise.allSettled(
                                    _.map(waitlists, ({ waitlist_id }) => {
                                        return updateWaitlistForUser(
                                            pharmacyId,
                                            locationId,
                                            user.userId,
                                            waitlist_id,
                                            'Scheduled'
                                        );
                                    })
                                );

                                return created;
                            }}
                            onNotInterested={() => {
                                const waitlists = _.filter(
                                    _.get(user, 'fetchingWaitlists.response.waitlists'),
                                    ({ status }) => status !== 'DECLINED'
                                );

                                return Promise.allSettled(
                                    _.map(waitlists, ({ waitlist_id }) => {
                                        return updateWaitlistForUser(
                                            pharmacyId,
                                            locationId,
                                            user.userId,
                                            waitlist_id,
                                            'Declined'
                                        );
                                    })
                                );
                            }}
                        />
                    </div>
                </div>
            </span>
        );
    } else if (
        !_.isNil(messaging_form) &&
        _.get(messaging_form, 'scheduling_type') === '3rd-party' &&
        _.isString(_.get(messaging_form, 'third_party_scheduling_link'))
    ) {
        return (
            <div className="conversation-message-cta__bubble">
                <div
                    style={{
                        background: '#f7f7f7',
                        marginRight: '8px',
                    }}
                >
                    <ThirdPartySchedulerForm
                        thirdPartySchedulerUrl={_.get(messaging_form, 'third_party_scheduling_link')}
                        primaryColor="#00B18F"
                        showNotInterested={!_.isEmpty(_.get(messaging_form, 'waitlist_ids'))}
                        notInterestedText={_.get(messaging_form, 'notInterestedText')}
                        getPreviouslySaidNotInterested={() => {
                            return hasUserDeclinedScheduling(user, messaging_form.waitlist_ids);
                        }}
                        onNotInterested={() => {
                            const waitlists = _.filter(
                                _.get(user, 'fetchingWaitlists.response.waitlists'),
                                ({ status }) => status !== 'DECLINED'
                            );

                            return Promise.allSettled(
                                _.map(waitlists, ({ waitlist_id }) => {
                                    return updateWaitlistForUser(
                                        pharmacyId,
                                        locationId,
                                        user.userId,
                                        waitlist_id,
                                        'Declined'
                                    );
                                })
                            );
                        }}
                        onOpenScheduler={() => {
                            Snowplow.structEvent('Message', 'patient-cta-clicked', [
                                new InboxMessageContext()
                                    .setConversationId(inbox_conversation_id)
                                    .setMessageId(inbox_message_id)
                                    .build(),
                            ]);
                            window.location = _.get(messaging_form, 'third_party_scheduling_link');
                        }}
                    />
                </div>
            </div>
        );
    } else if (!_.isNil(_.get(messaging_form, 'appt_type_id'))) {
        return (
            <div className="conversation-message-cta__bubble">
                <div
                    style={{
                        background: '#f7f7f7',
                        marginRight: '8px',
                    }}
                >
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            window.location =
                                `${getAppBaseUrl()}/assessment-form/?configId=${pharmacyId}` +
                                `&userId=${user.userId}&apptTypeId=${messaging_form.appt_type_id}` +
                                `&convId=${inbox_conversation_id}&msgId=${inbox_message_id}`;
                        }}
                    >
                        {action_title}
                    </Button>
                </div>
            </div>
        );
    }

    return <div />;
}

function hasAnswerChanged(answer, questionId, savedAssessmentAnswers) {
    return _.get(savedAssessmentAnswers, `${questionId}.answer`) !== answer;
}

const mapStateToProps = (state) => {
    return {
        fullPepEnabled: _.get(pharmacySelectors.pharmacyActiveLocationSelector(state), 'service.Inbox.FullPepEnabled'),
    };
};

export default connect(mapStateToProps, () => ({}))(MessageCTACard);

function getAppBaseUrl() {
    if (window.ENV_CONFIG.environ === 'dev') {
        return 'https://app.dev.digitalpharmacist.com';
    } else if (window.ENV_CONFIG.environ === 'staging') {
        return 'https://app.staging.digitalpharmacist.com';
    } else {
        return 'https://app.digitalpharmacist.com';
    }
}
