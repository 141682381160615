import axios from 'axios';

const login = (userId, dateOfBirth) => {
    const requestBody = {
        inbox_user_id: userId,
        date_of_birth: dateOfBirth,
    };
    return axios
        .post(`/api/v1/inbox/patient/login`, requestBody)
        .then((response) => {
            if (response.data.success) {
                return true;
            } else if (response.data.message) {
                const errorObj = {
                    message: response.data.message,
                };
                return Promise.reject(errorObj);
            }
        })
        .catch((error) => {
            error.message = error.message || 'An unknown error has occurred';
            return Promise.reject(error);
        });
};

const getUserData = (userId) => {
    return axios
        .get(`/api/v1/inbox/patient/user/${userId}`)
        .then((response) => {
            return response.data.user;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

const getUserPharmacyData = (userId) => {
    return axios
        .get(`/api/v1/inbox/patient/user/${userId}/details`)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

const getConsentTypes = ({ pharmacyId }) => {
    return axios
        .get('/api/v1/inbox/patient/consents?types=TermsConditions,Message,PrivacyPolicy,PharmacyPrivacyPolicy', {
            headers: {
                'X-Pharmacy-Id': pharmacyId,
            },
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

const consentMap = {
    privacy_consent: 'PrivacyPolicy',
    terms_conditions_consent: 'TermsConditions',
    message_consent: 'Message',
    pharmacy_privacy_policy_consent: 'PharmacyPrivacyPolicy',
};

const submitConsent = (userId, consentTypes) => {
    const consents = [];
    consentTypes.forEach((consentType) => {
        consents.push(consentMap[consentType]);
    });
    return axios
        .post(`/api/v1/inbox/patient/user/${userId}/consent`, consents)
        .then((response) => {
            if (response.data.success) {
                return true;
            } else {
                return Promise.reject({});
            }
        })
        .catch((error) => {
            error.message = error.message || 'An unknown error has occurred';
            return Promise.reject(error);
        });
};

const getReservationsForUser = (pharmacyId, locationId, userId, status = 'ANY') => {
    return axios
        .get(
            `/api/v1/appts/patient/${pharmacyId}/location/${locationId}/reservations-for-user/${userId}?status=${status}`
        )
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

const getWaitlistsForUser = (pharmacyId, locationId, userId) => {
    return axios
        .get(`/api/v1/inbox/patient/waitlist-status/${pharmacyId}/${locationId}/${userId}`)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

const updateWaitlistForUser = (pharmacyId, locationId, userId, waitlistId, status) => {
    return axios
        .post(
            // /api/v1/inbox/patient/assessments/record-answers/
            `/api/v1/inbox/patient/update-waitlist/${pharmacyId}/${locationId}/${userId}/${waitlistId}?status=${status}`
        )
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

export const userService = {
    login,
    getUserData,
    getUserPharmacyData,
    getConsentTypes,
    submitConsent,
    getReservationsForUser,
    getWaitlistsForUser,
    updateWaitlistForUser,
};

export default userService;
