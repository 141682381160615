import axios from "axios";

const getPharmacyData = pharmacyId => {
    return axios
      .get(`/api/v1/config/${pharmacyId}`)
      .then(response => {
      return response.data;

      })
      .catch(error => {
        return Promise.reject(error);
      });
};

export const pharmacyService = {
    getPharmacyData
};

export default pharmacyService;
