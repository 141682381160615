import React, { Fragment } from 'react';
import './ImageUploadPreview.css';
import SendIcon from '@material-ui/icons/Send';
import ClearIcon from '@material-ui/icons/Clear';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';

class ImageUploadPreview extends React.Component {
	removeImage = () => {
		this.props.handleAttachment('');
	};

	render() {
		const { imageUrl, sending } = this.props;

		return (
			<Fragment>
				<div className="conversation-message-input__content">
					<IconButton
						className="remove"
						onClick={this.removeImage}
						size="small"
					>
						<ClearIcon style={{ fontSize: '24px' }} />
					</IconButton>
					<div className="img-thumbnail">
						<img
							src={imageUrl}
							alt="upload"
							style={{ maxWidth: '100%', maxHeight: '150px' }}
						/>
					</div>
				</div>
				<div className="conversation-message-input__submit">
					<IconButton
						aria-haspopup="true"
						color="inherit"
						onClick={this.props.onImageSubmit}
						disabled={sending}
						className="conversation-message-input__send nav-icon-padding"
					>
						{sending ? (
							<CircularProgress
								size={20}
								className="conversation-message-input__progress"
							/>
						) : (
							<SendIcon style={{ color: '#757575' }} />
						)}
					</IconButton>
				</div>
			</Fragment>
		);
	}
}

export default ImageUploadPreview;
