import _ from 'lodash';

export function getAppointmentMatchingMessage(userState, inbox_message_id) {
    return _.find(
        _.get(userState, 'fetchingReservations.response.reservations'),
        (reservation) => reservation.inbox_message_id === inbox_message_id
    );
}

export function getReservationById(userState, reservation_id) {
    return _.find(
        _.get(userState, 'fetchingReservations.response.reservations'),
        (reservation) => reservation.reservation_id === reservation_id
    );
}

export function hasUserDeclinedScheduling(userState, waitlist_ids) {
    const templateIds = new Set(waitlist_ids);
    
    const waitlists = _.get(userState, 'fetchingWaitlists.response.waitlists');
    const matchingWaitlists = _.filter((waitlists), ({waitlist_id, status}) => templateIds.has(waitlist_id) && status !== 'Declined');
    
    return !_.isEmpty(waitlists) && _.size(matchingWaitlists) === 0;
}