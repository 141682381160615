import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import './Login.css';
import { TextField, Button, FormHelperText, IconButton } from '@material-ui/core';
import { CircularProgress, Typography } from '@material-ui/core/';
import { BirthdayMask } from '../utils/mask';
import {
    isEmptyString,
    formatRawDate,
    makePossessive,
    formatRawPhone,
    getDateInputFormatByCountryCode,
} from '../utils/helper';
import { connect } from 'react-redux';
import { userAction } from '../redux/User';
import Snowplow from '../snowplow';
import { pharmacySelectors } from '../redux/Pharmacy/selector';
import CloseIcon from '@material-ui/icons/Close';

const baseLoginState = {
    dateOfBirth: '',
    errors: {},
};

class UserLogin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...baseLoginState,
        };

        this.commonProps = {
            fullWidth: true,
            variant: 'outlined',
            margin: 'normal',
            required: true,
            onChange: this.handleChange,
        };

        const { activeLocationCountryCode } = this.props;
        this.dateFormat = getDateInputFormatByCountryCode({ countryCode: activeLocationCountryCode });
    }

    componentDidMount = () => {
        Snowplow.pageView('Login');
    };

    componentDidUpdate = (prevProps) => {
        const { user } = this.props;

        if (user.loginSuccess && !prevProps.user.loginSuccess) {
            this.props.getUserData();
        }
    };

    validateDate = (rawDate) => {
        const date = moment(rawDate, this.dateFormat);
        const tomorrow = moment(new Date()).add(1, 'days');
        const baseDate = moment('01/01/1900', this.dateFormat);
        if (!date.isValid()) {
            return 'Please enter a valid date';
        } else if (!date.isBetween(baseDate, tomorrow)) {
            return `Date needs to be between ${baseDate.format(this.dateFormat)} - ${tomorrow.format(this.dateFormat)}`;
        }

        return false;
    };

    getValidationErrors = () => {
        const { dateOfBirth } = this.state;
        const errors = {};
        const emptyMessage = 'This field is required';

        errors.dateOfBirth = isEmptyString(dateOfBirth) ? emptyMessage : this.validateDate(dateOfBirth);

        // Remove all values that are false
        Object.keys(errors).forEach((key) => errors[key] === false && delete errors[key]);

        return errors;
    };

    handleSubmit = (event) => {
        // Cancel default form submission behavior
        event.preventDefault();
        // Validate inputs
        const errors = this.getValidationErrors();
        if (!_.isEmpty(errors)) {
            // Validation failed
            this.setState({ errors });
        } else {
            // Validation passed
            const dateOfBirth = formatRawDate(this.state.dateOfBirth, this.dateFormat, 'YYYY-MM-DD');
            this.props.loginUser(dateOfBirth);

            this.props.onLogin();
        }
    };

    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState({ [name]: value });
    };

    render() {
        const { dateOfBirth, errors } = this.state;
        const { user, userDetails, pharmacyDisplayName, pharmacyLocationPhone, activeLocationCountryCode } = this.props;
        const patientFirstName = userDetails.firstName;

        return (
            <div className="user-login">
                <div className="user-login__pharmacy">{pharmacyDisplayName}</div>
                <div className="user-login__form">
                    {patientFirstName && (
                        <div className="user-login__form-helper">
                            <Typography variant="body1">
                                You have a new message for
                                <span style={{ fontWeight: 'bolder' }}> {patientFirstName}</span>
                            </Typography>
                            <Typography variant="body1">
                                {`Enter ${makePossessive(patientFirstName)} birthday to see the message`}
                            </Typography>
                        </div>
                    )}
                    <form onSubmit={this.handleSubmit} noValidate>
                        <TextField
                            type="text"
                            id="dateOfBirth"
                            name="dateOfBirth"
                            label="Birthday"
                            placeholder={this.dateFormat}
                            value={dateOfBirth}
                            InputProps={{
                                inputComponent: BirthdayMask,
                                inputProps: {
                                    pattern: '[0-9]*',
                                    inputMode: 'numeric',
                                },
                            }}
                            error={!!errors.dateOfBirth}
                            helperText={errors.dateOfBirth}
                            {...this.commonProps}
                        />
                        {user.loginMessage ? <FormHelperText error>{user.loginMessage}</FormHelperText> : ''}
                        <div className="user-login__form-submit-wrap">
                            <Button
                                onClick={this.handleSubmit}
                                color="primary"
                                disabled={user.loginLoading}
                                variant="outlined"
                            >
                                {user.loginLoading ? (
                                    <CircularProgress size={20} className="user-login__form__progress" />
                                ) : (
                                    'Next'
                                )}
                            </Button>
                        </div>
                    </form>

                    <div className="faq-link">
                        <Button
                            className="faq-button"
                            fullWidth
                            style={{
                                textTransform: 'none',
                                color: '#1A84FF',
                                textDecoration: 'underline',
                                marginTop: '40px',
                            }}
                            onClick={() => this.setState({ showFaq: true })}
                        >
                            Why do i need to enter my birthday?
                        </Button>
                    </div>
                </div>
                <div className="user-login__pharmacy-phone">
                    {pharmacyLocationPhone && (
                        <Typography variant="body1">
                            If you are experiencing any issues, please call the pharmacy at{' '}
                            <a href={`tel:${pharmacyLocationPhone}`}>
                                {formatRawPhone(pharmacyLocationPhone, activeLocationCountryCode)}
                            </a>
                        </Typography>
                    )}
                </div>

                {!this.state.showFaq ? null : (
                    <FrequentyAskedQuestionsModal
                        open={true}
                        onClose={() => this.setState({ showFaq: false })}
                        phoneLink={
                            <a href={`tel:${pharmacyLocationPhone}`}>
                                {formatRawPhone(pharmacyLocationPhone, activeLocationCountryCode)}
                            </a>
                        }
                    />
                )}
            </div>
        );
    }
}

function FrequentyAskedQuestionsModal({ onClose, phoneLink }) {
    return (
        <div className="frequently-asked-questions-outer">
            <div className="frequently-asked-questions-modal">
                <div className="heading">
                    <div className="title">Frequently Asked Questions</div>
                    <IconButton onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </div>

                <div className="question-and-answer">
                    <div className="question">Why do I need to enter my birthday?</div>
                    <div className="answer">
                        Our pharmacy is using a secure 2-way messaging application to streamline patient communication.
                        Entering your birthday ensures that this message is not visible to anybody other than you.
                    </div>
                </div>

                <div className="question-and-answer">
                    <div className="question">How do I know this message is from my pharmacy?</div>
                    <div className="answer">
                        The best way to know that this message is from your pharmacy is to ask them. You can call our
                        pharmacy at {phoneLink} to confirm that this message is from us.
                    </div>
                </div>

                <div className="question-and-answer">
                    <div className="question">What should I do if it does not let me log in with my birthday?</div>
                    <div className="answer">
                        Please ensure that you are providing your birthday in the proper format. If you are still unable
                        to log in it could indicate that our pharmacy has an incorrect birthday for you. Please call us
                        at {phoneLink} and request that we update your birthday.
                    </div>
                </div>
            </div>
        </div>
    );
}

function mapStateToProps(state) {
    const { pharmacy, user } = state;
    return {
        pharmacy,
        user,
        activeLocationCountryCode: pharmacySelectors.pharmacyActiveLocationCountryCodeSelector(state),
    };
}

const bindActionsToDispatch = (dispatch) => ({
    getUserData: () => dispatch(userAction.getUserData()),
    loginUser: (dateOfBirth) => dispatch(userAction.login(dateOfBirth)),
});

export default connect(mapStateToProps, bindActionsToDispatch)(UserLogin);
