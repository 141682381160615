import messageType from './type';
import _ from 'lodash';

export const initialState = {
    loading: false,
    sending: false,
    messages: [],
    selectedFile: '',
    error: {},
    assessments: {},
    savedAssessmentAnswers: {},
    recordingAssessmentAnswers: false,
    fetchingReservationSession: null,
    fetchingCalendar: null,
};

export const messageReducer = (state = initialState, action) => {
    switch (action.type) {
        case messageType.RESET_MESSAGE_STORE:
            return {
                ...initialState,
            };
        case messageType.GET_MESSAGES_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case messageType.GET_MESSAGES_SUCCESS:
            return {
                ...state,
                loading: false,
                messages: action.messages,
            };
        case messageType.GET_MESSAGES_FAILURE:
            return {
                ...initialState,
                error: action.error,
            };
        case messageType.CREATE_MESSAGE_REQUEST:
            return {
                ...state,
                sending: true,
            };
        case messageType.CREATE_MESSAGE_SUCCESS:
            return {
                ...state,
                sending: false,
            };
        case messageType.CREATE_MESSAGE_FAILURE:
            return {
                ...state,
                sending: false,
                error: action.error,
            };
        case messageType.CREATE_ATTACHMENT_REQUEST:
            return {
                ...state,
                sending: true,
            };
        case messageType.CREATE_ATTACHMENT_SUCCESS:
            return {
                ...state,
                sending: false,
            };
        case messageType.CREATE_ATTACHMENT_FAILURE:
            return {
                ...state,
                sending: false,
                error: action.error,
            };
        case messageType.GET_LATEST_MESSAGE_REQUEST:
            return {
                ...state,
            };
        case messageType.GET_LATEST_MESSAGE_SUCCESS:
            return {
                ...state,
            };
        case messageType.GET_LATEST_MESSAGE_FAILURE:
            return {
                ...state,
            };
        case messageType.UPDATE_MESSAGE_READ_STATUS_REQUEST:
            return {
                ...state,
            };
        case messageType.UPDATE_MESSAGE_READ_STATUS_SUCCESS:
            return {
                ...state,
            };
        case messageType.UPDATE_MESSAGE_READ_STATUS_FAILURE:
            return {
                ...state,
            };
        case messageType.RECORD_ASSESSMENT_ANSWERS_REQUEST:
            return {
                ...state,
                recordingAssessmentAnswers: true,
            };
        case messageType.RECORD_ASSESSMENT_ANSWERS_SUCCESS:
            return {
                ...state,
                recordingAssessmentAnswers: false,
                savedAssessmentAnswers: {
                    ...state.savedAssessmentAnswers,
                    [action.inboxMessageId]: {
                        inboxMessageId: action.inboxMessageId,
                        answers: {
                            ..._.get(state, `savedAssessmentAnswers.${action.inboxMessageId}.answers`, {}),
                            ...action.answers,
                        },
                    },
                },
            };
        case messageType.RECORD_ASSESSMENT_ANSWERS_FAILURE:
            return {
                ...state,
                recordingAssessmentAnswers: false,
            };

        case messageType.FETCH_CALENDAR_REQUEST:
        case messageType.FETCH_CALENDAR_SUCCESS:
        case messageType.FETCH_CALENDAR_FAILURE:
            return {
                ...state,
                fetchingCalendar: {
                    loading: action.type === messageType.FETCH_CALENDAR_REQUEST,
                    ...action.payload,
                },
            };

        case messageType.FETCH_RESERVATION_SESSION_REQUEST:
        case messageType.FETCH_RESERVATION_SESSION_SUCCESS:
        case messageType.FETCH_RESERVATION_SESSION_FAILURE:
            return {
                ...state,
                fetchingReservationSession: {
                    loading: action.type === messageType.FETCH_RESERVATION_SESSION_REQUEST,
                    ...action.payload,
                },
            };

        case messageType.FETCH_AVAILABLE_SLOTS_REQUEST:
        case messageType.FETCH_AVAILABLE_SLOTS_SUCCESS:
        case messageType.FETCH_AVAILABLE_SLOTS_FAILURE:
            return {
                ...state,
                fetchingAvailableSlots: {
                    loading: action.type === messageType.FETCH_AVAILABLE_SLOTS_REQUEST,
                    ...action.payload,
                },
            };

        case messageType.CREATE_RESERVATION_REQUEST:
        case messageType.CREATE_RESERVATION_SUCCESS:
        case messageType.CREATE_RESERVATION_FAILURE:
            return {
                ...state,
                creatingReservation: {
                    loading: action.type === messageType.CREATE_RESERVATION_REQUEST,
                    ...action.payload,
                },
            };

        case messageType.CHECKIN_RESERVATION_REQUEST:
        case messageType.CHECKIN_RESERVATION_SUCCESS:
        case messageType.CHECKIN_RESERVATION_FAILURE:
            return {
                ...state,
                checkingInReservation: {
                    loading: action.type === messageType.CHECKIN_RESERVATION_REQUEST,
                    ...action.payload,
                },
            };

        default:
            return state;
    }
};

export default messageReducer;
