import pharmacyType from './type.js';
import pharmacyService from './service.js';

const setPharmacyId = (pharmacyId) => {
    return {
        type: pharmacyType.SET_PHARMACY_ID,
        pharmacyId: pharmacyId,
    };
};

const setLocationId = (locationId) => {
    return {
        type: pharmacyType.SET_LOCATION_ID,
        locationId: locationId,
    };
};

const getPharmacyData = () => {
    return function (dispatch, getState) {
        dispatch(request());
        const pharmacyId = getState().pharmacy.pharmacyId;

        pharmacyService.getPharmacyData(pharmacyId).then(
            (pharmacyData) => {
                dispatch(success(pharmacyData));
            },
            (error) => {
                dispatch(failure(error.toString()));
            }
        );
    };

    function request() {
        return { type: pharmacyType.GET_PHARMACY_REQUEST };
    }
    function success(pharmacyData) {
        return { type: pharmacyType.GET_PHARMACY_SUCCESS, pharmacyData };
    }
    function failure(error) {
        return { type: pharmacyType.GET_PHARMACY_FAILURE, error };
    }
};

export const pharmacyAction = {
    setPharmacyId,
    setLocationId,
    getPharmacyData,
};

export default pharmacyAction;
