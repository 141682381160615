import React, { Fragment } from 'react';
import IconButton from '@material-ui/core/IconButton';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';

class ImageUpload extends React.Component {
	componentDidUpdate = prevProps => {
		if (!this.props.imageUrl && prevProps.imageUrl) {
			this.attachmentInput.value = '';
		}
	};

	onImageChange = event => {
		const selectedFile = event.target.files[0];
		const reader = new FileReader();

		reader.onabort = () => {
			this.props.handleAttachment(null, null);
		};
		reader.onerror = () => {
			this.props.handleAttachment(null, null);
		};
		reader.onload = () => {
			this.props.handleAttachment(reader.result, selectedFile);
		};
		if (selectedFile) {
			reader.readAsDataURL(selectedFile);
		} else {
			this.props.handleAttachment(null, null);
		}
	};

	render() {
		return (
			<Fragment>
				<form>
					<input
						id="contained-button-file"
						type="file"
						ref={el => (this.attachmentInput = el)}
						name="selectedFile"
						onChange={this.onImageChange}
						accept="image/*"
						style={{ display: 'none' }}
					/>
				</form>
				<label htmlFor="contained-button-file">
					<IconButton
						variant="contained"
						component="span"
						className="conversation-message-input__add nav-icon-padding"
					>
						<AddAPhotoIcon fontSize="default" />
					</IconButton>
				</label>
			</Fragment>
		);
	}
}

export default ImageUpload;
