import pharmacyType from "./type";

export const initialState = {
    loading: false,
    pharmacyId: null,
    locationId: null,
    pharmacyData: {},
    error: {}
};

export const pharmacyReducer = (state = initialState, action) => {
    switch (action.type) {
        case pharmacyType.SET_PHARMACY_ID:
        return {
            ...state,
            pharmacyId: action.pharmacyId
        };
        case pharmacyType.SET_LOCATION_ID:
        return {
            ...state,
            locationId: action.locationId
        };
        case pharmacyType.GET_PHARMACY_REQUEST:
        return {
            ...state,
            loading: true
        };
        case pharmacyType.GET_PHARMACY_SUCCESS:
        return {
            ...state,
            loading: false,
            pharmacyData: action.pharmacyData
        };
        case pharmacyType.GET_PHARMACY_FAILURE:
        return {
            ...state,
            loading: false,
            pharmacyData: {},
            error: action.error
        };
        default:
        return state;
    }
};

export default pharmacyReducer;
