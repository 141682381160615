import _ from 'lodash';
import { createSelector } from 'reselect';

const pharmacyActiveLocationIdSelector = (state) => {
    const { locationId } = state.pharmacy;
    return locationId;
};

const pharmacyLocationsSelector = (state) => {
    const { location } = state.pharmacy.pharmacyData;
    return location;
};

const pharmacyActiveLocationSelector = createSelector(
    pharmacyActiveLocationIdSelector,
    pharmacyLocationsSelector,
    (activeLocationId, locations) => {
        return _.find(locations, { id: activeLocationId });
    }
);

const pharmacyActiveLocationCountryCodeSelector = createSelector(pharmacyActiveLocationSelector, (activeLocation) => {
    return _.toUpper(_.get(activeLocation, 'address.Main.country', 'US'));
});

export const pharmacySelectors = {
    pharmacyActiveLocationIdSelector,
    pharmacyLocationsSelector,
    pharmacyActiveLocationSelector,
    pharmacyActiveLocationCountryCodeSelector,
};