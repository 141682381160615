import userType from './type.js';
import userService from './service.js';
import _ from 'lodash';

const resetUserStore = () => {
    return {
        type: userType.RESET_USER_STORE,
    };
};

const setUserId = (userId) => {
    return {
        type: userType.SET_USER_ID,
        userId: userId,
    };
};

const getUserPharmacyData = (userId) => {
    return function (dispatch) {
        dispatch(request());
        userService.getUserPharmacyData(userId).then(
            (userPharmacyData) => {
                dispatch(success(userPharmacyData));
                dispatch(userAction.getConsentTypes());
            },
            (error) => {
                dispatch(failure(error.toString()));
            }
        );
    };

    function request() {
        return { type: userType.GET_USER_PHARMACY_REQUEST };
    }
    function success(userPharmacyData) {
        return {
            type: userType.GET_USER_PHARMACY_SUCCESS,
            pharmacyId: userPharmacyData.pharmacy_id,
            locationId: userPharmacyData.location_id,
            firstName: userPharmacyData.first_name,
        };
    }
    function failure(error) {
        return { type: userType.GET_USER_PHARMACY_FAILURE, error };
    }
};

const getUserData = () => {
    return function (dispatch, getState) {
        dispatch({ type: userType.GET_USER_REQUEST });
        const userId = getState().user.userId;
        userService.getUserData(userId).then(
            (userData) => {
                dispatch({ type: userType.GET_USER_SUCCESS, userData });
            },
            (error) => {
                dispatch({ type: userType.GET_USER_FAILURE, error: error.toString() });
            }
        );
    };
};

const logout = () => {
    return {
        type: userType.LOGOUT,
    };
};

const login = (dateOfBirth) => {
    return function (dispatch, getState) {
        dispatch(request());
        const userId = getState().user.userId;

        userService.login(userId, dateOfBirth).then(
            (userData) => {
                dispatch(success());
            },
            (error) => {
                dispatch(failure(error));
            }
        );
    };

    function request() {
        return { type: userType.LOGIN_REQUEST };
    }
    function success() {
        return { type: userType.LOGIN_SUCCESS };
    }
    function failure(error) {
        return { type: userType.LOGIN_FAILURE, error };
    }
};

const submitConsent = (consentTypes) => {
    return function (dispatch, getState) {
        dispatch({ type: userType.CONSENT_REQUEST });
        const userId = getState().user.userId;

        userService.submitConsent(userId, consentTypes).then(
            (consentData) => {
                dispatch({ type: userType.CONSENT_SUCCESS, consentTypes });
            },
            (error) => {
                dispatch({ type: userType.CONSENT_FAILURE, error });
            }
        );
    };
};

const getReservationsForUser = (pharmacyId, locationId, userId, status) => {
    return async function (dispatch, getState) {
        if (_.get(getState(), 'user.fetchingReservations.loading')) {
            return Promise.resolve();
        }

        dispatch({
            type: userType.GET_RESERVATIONS_FOR_USER_REQUEST,
            payload: { pharmacyId, locationId, userId, status },
        });

        try {
            const response = await userService.getReservationsForUser(pharmacyId, locationId, userId, status);
            dispatch({
                type: userType.GET_RESERVATIONS_FOR_USER_SUCCESS,
                payload: { pharmacyId, locationId, userId, status, response },
            });

            return response;
        } catch (error) {
            dispatch({
                type: userType.GET_RESERVATIONS_FOR_USER_FAILURE,
                payload: { pharmacyId, locationId, userId, status, error },
            });
        }
    };
};

const getWaitlistsForUser = (pharmacyId, locationId, userId) => {
    return async function (dispatch, getState) {
        if (_.get(getState(), 'user.fetchingWaitlists.loading')) {
            return Promise.resolve();
        }

        dispatch({
            type: userType.GET_WAITLISTS_FOR_USER_REQUEST,
            payload: { pharmacyId, locationId, userId },
        });

        try {
            const response = await userService.getWaitlistsForUser(pharmacyId, locationId, userId);
            dispatch({
                type: userType.GET_WAITLISTS_FOR_USER_SUCCESS,
                payload: { pharmacyId, locationId, userId, response },
            });

            return response;
        } catch (error) {
            dispatch({
                type: userType.GET_WAITLISTS_FOR_USER_FAILURE,
                payload: { pharmacyId, locationId, userId, error },
            });
        }
    };
};

const updateWaitlistForUser = (pharmacyId, locationId, userId, waitlistId, status) => {
    return async function (dispatch, getState) {
        if (_.get(getState(), `user.updatingWaitlist.${waitlistId}.loading`)) {
            return Promise.resolve();
        }

        dispatch({
            type: userType.UPDATE_WAITLIST_FOR_USER_REQUEST,
            payload: { pharmacyId, locationId, userId, waitlistId, status },
        });

        try {
            const response = await userService.updateWaitlistForUser(
                pharmacyId,
                locationId,
                userId,
                waitlistId,
                status
            );
            dispatch({
                type: userType.UPDATE_WAITLIST_FOR_USER_SUCCESS,
                payload: { pharmacyId, locationId, userId, waitlistId, status, response },
            });

            return response;
        } catch (error) {
            dispatch({
                type: userType.UPDATE_WAITLIST_FOR_USER_FAILURE,
                payload: { pharmacyId, locationId, userId, waitlistId, status, error },
            });
        }
    };
};

const getConsentTypes = () => {
    return function (dispatch, getState) {
        const { pharmacyId } = getState().pharmacy;
        dispatch({ type: userType.GET_CONSENT_REQUEST });
        userService
            .getConsentTypes({ pharmacyId })
            .then(({ consent }) => {
                dispatch({
                    type: userType.GET_CONSENT_SUCCESS,
                    data: {
                        message: consent.Message,
                        privacyPolicy: consent.PrivacyPolicy,
                        termsConditions: consent.TermsConditions,
                        pharmacyPrivacyPolicy: consent.PharmacyPrivacyPolicy,
                    },
                });
            })
            .catch((error) => {
                dispatch({ type: userType.GET_CONSENT_FAILURE, error });
            });
    };
};

const appendNewReservation = (newReservation) => {
    return {
        type: userType.APPEND_NEW_RESERVATION,
        payload: { newReservation },
    };
};

export const userAction = {
    resetUserStore,
    login,
    logout,
    setUserId,
    getUserData,
    getUserPharmacyData,
    getConsentTypes,
    submitConsent,
    getReservationsForUser,
    getWaitlistsForUser,
    updateWaitlistForUser,
    appendNewReservation,
};

export default userAction;
