import conversationType from './type.js';
import conversationService from './service.js';

const resetConversationStore = () => {
    return {
        type: conversationType.RESET_CONVERSATION_STORE
    }
};

const setActiveConversation = (conversationId) => {
    return function(dispatch, getState) {
        const conversationState = getState().conversation;
        const { conversations } = conversationState;
        conversations.forEach((conversation) => {
            if (conversation.inbox_conversation_id === conversationId) {
                dispatch({
                    type: conversationType.SET_ACTIVE_CONVERSATION,
                    activeConversation: conversation,
                    activeConversationId: conversationId
                })
            }
        });
    };
};

const clearActiveConversation = () => {
    return {
        type: conversationType.CLEAR_ACTIVE_CONVERSATION
    }
};

const getConversations = () => {
    return function(dispatch, getState) {
        dispatch(request());
        const userId = getState().user.userData.inbox_user_id;

        conversationService.getConversations(userId)
            .then(
                conversations => {
                    dispatch(success(conversations));
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() { return { type: conversationType.GET_CONVERSATIONS_REQUEST } }
    function success(conversations) { return { type: conversationType.GET_CONVERSATIONS_SUCCESS, conversations } }
    function failure(error) { return { type: conversationType.GET_CONVERSATIONS_FAILURE, error } }
};

export const conversationAction = {
    resetConversationStore,
    setActiveConversation,
    clearActiveConversation,
    getConversations
};

export default conversationAction;