import { userType, userAction } from './User';
import { conversationType, conversationAction } from './Conversation';
import { messageType, messageAction } from './Message';
import { pharmacyAction } from './Pharmacy';
import { socketType } from './Socket';
import _ from 'lodash';


const inboxMiddleware = store => next => action => {
    if (action.type === userType.LOGOUT) {
        // Clearing user since logout
        store.dispatch(userAction.resetUserStore());
    } else if (action.type === userType.RESET_USER_STORE) {
        // Clearing conversation since user is reset
        store.dispatch(conversationAction.resetConversationStore());
    } else if (action.type === conversationType.RESET_CONVERSATION_STORE) {
        // Clearing message since conversation is being reset
        store.dispatch(messageAction.resetMessageStore());
    } else if (action.type === conversationType.CLEAR_ACTIVE_CONVERSATION) {
        // Refresh conversations since active conversation has been reset
        store.dispatch(conversationAction.getConversations());
        // Reset message store since active conversation has been reset
        store.dispatch(messageAction.resetMessageStore());
    } else if (action.type === messageType.CREATE_MESSAGE_SUCCESS) {
        // Refresh message list after creating new message
        // store.dispatch(messageAction.getMessages());
    } else if (action.type === messageType.GET_LATEST_MESSAGE_SUCCESS) {
        const { message } = store.getState();
        if (message.messages && message.messages[message.messages.length - 1].inbox_message_id !== action.latestMessage.inbox_message_id) {
            // Refresh message list since latest message doesn't match current model
            store.dispatch(messageAction.getMessages());
      }
    } else if (action.type === userType.GET_USER_PHARMACY_SUCCESS) {
        store.dispatch(pharmacyAction.setLocationId(action.locationId));
        store.dispatch(pharmacyAction.setPharmacyId(action.pharmacyId));
    } else if (action.type === socketType.SOCKET_RECEIVE_MESSAGE) {
        if (action.payload && action.payload.messageType === 'createMessage') {
            console.warn(action.payload.messageData);

            const { conversation } = store.getState();
            const { activeConversationId } = conversation;

            if (activeConversationId) {
                // If active conversation, refresh messages
                store.dispatch(messageAction.getMessages());
            } else {
                // Else, refresh conversation list
                store.dispatch(conversationAction.getConversations());
            }
        }
    } else if (action.type === messageType.RECORD_ASSESSMENT_ANSWERS_SUCCESS) {
        // update messages with the new recorded answers
        const {answers, inboxMessageId} = action;
        
        const messages = _.map(_.get(store.getState(), 'message.messages'), (message) => {
            const messageClone = _.cloneDeep(message);
            if (message.inbox_message_id === inboxMessageId) {
                messageClone.content.messaging_form.saved_assessment_answers = answers;
            }
            return messageClone;
        });
        
        // dispatch it here
        store.dispatch({ type: messageType.GET_MESSAGES_SUCCESS, messages });
    
    } else if (action.type === messageType.CREATE_RESERVATION_SUCCESS) {
        // if we created a new reservation then append it to the ones we got a list of on login
        const newReservation = _.get(action, 'payload.response');
        store.dispatch(userAction.appendNewReservation(newReservation));
    }
    
    next(action);
};

export default inboxMiddleware;
