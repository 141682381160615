import { socketAction } from './Socket';
import { userType } from './User';


const socketMiddleware = store => next => action => {
    if (action.type === userType.GET_USER_SUCCESS) {
        const { user } = store.getState();
        const { userId } = user;
        store.dispatch(socketAction.connectSocket(userId));
    } else if (action.type === userType.LOGOUT) {
        store.dispatch(socketAction.disconnectSocket());
    }
    next(action);
};

export default socketMiddleware;
