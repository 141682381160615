export const userType = {
    RESET_USER_STORE: "RESET_USER_STORE",
    SET_USER_ID: "SET_USER_ID",
    LOGOUT: "USER_LOGOUT",
    LOGIN_REQUEST: "USER_LOGIN_REQUEST",
    LOGIN_SUCCESS: "USER_LOGIN_SUCCESS",
    LOGIN_FAILURE: "USER_LOGIN_FAILURE",
    CONSENT_REQUEST: "USER_CONSENT_REQUEST",
    CONSENT_SUCCESS: "USER_CONSENT_SUCCESS",
    CONSENT_FAILURE: "USER_CONSENT_FAILURE",
    GET_CONSENT_REQUEST: "GET_CONSENT_REQUEST",
    GET_CONSENT_SUCCESS: "GET_CONSENT_SUCCESS",
    GET_CONSENT_FAILURE: "GET_CONSENT_FAILURE",
    GET_USER_REQUEST: "USER_GET_DATA_REQUEST",
    GET_USER_SUCCESS: "USER_GET_DATA_SUCCESS",
    GET_USER_FAILURE: "USER_GET_DATA_FAILURE",
    GET_USER_PHARMACY_REQUEST: "GET_USER_PHARMACY_REQUEST",
    GET_USER_PHARMACY_SUCCESS: "GET_USER_PHARMACY_SUCCESS",
    GET_USER_PHARMACY_FAILURE: "GET_USER_PHARMACY_FAILURE",
    
    GET_RESERVATIONS_FOR_USER_REQUEST: "GET_RESERVATIONS_FOR_USER_REQUEST",
    GET_RESERVATIONS_FOR_USER_SUCCESS: "GET_RESERVATIONS_FOR_USER_SUCCESS",
    GET_RESERVATIONS_FOR_USER_FAILURE: "GET_RESERVATIONS_FOR_USER_FAILURE",
    
    GET_WAITLISTS_FOR_USER_REQUEST: "GET_WAITLISTS_FOR_USER_REQUEST",
    GET_WAITLISTS_FOR_USER_SUCCESS: "GET_WAITLISTS_FOR_USER_SUCCESS",
    GET_WAITLISTS_FOR_USER_FAILURE: "GET_WAITLISTS_FOR_USER_FAILURE",
    
    UPDATE_WAITLIST_FOR_USER_REQUEST: "UPDATE_WAITLIST_FOR_USER_REQUEST",
    UPDATE_WAITLIST_FOR_USER_SUCCESS: "UPDATE_WAITLIST_FOR_USER_SUCCESS",
    UPDATE_WAITLIST_FOR_USER_FAILURE: "UPDATE_WAITLIST_FOR_USER_FAILURE",
    
    APPEND_NEW_RESERVATION: "APPEND_NEW_RESERVATION"
};

export default userType;
