import { combineReducers } from 'redux';
import { pharmacyReducer } from './Pharmacy';
import { userReducer } from './User';
import { conversationReducer } from './Conversation';
import { messageReducer } from './Message';
import { socketReducer } from './Socket';


const rootReducer = combineReducers({
    pharmacy: pharmacyReducer,
    user: userReducer,
    conversation: conversationReducer,
    message: messageReducer,
    socket: socketReducer,
});

export default rootReducer;
