import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { conversationAction } from '../redux/Conversation';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import LocalPharmacyIcon from '@material-ui/icons/LocalPharmacy';
import Badge from '@material-ui/core/Badge';
import './Conversation.css';
import { formatUTCToRelative } from '../utils/formatTime';
import MessageList from './MessageList';
import OverlayCircularProgress from './OverlayCircularProgress';
import Snowplow from '../snowplow';
import { pharmacySelectors } from '../redux/Pharmacy/selector';

export class Conversation extends Component {
    static getContentPreview({ latestMessage }) {
        if (latestMessage.content_type === 'attachment') {
            return `Attachment: ${latestMessage.content.file_name}`;
        }

        if (latestMessage.content_type === 'call_to_action') {
            return latestMessage.content.action_title;
        }

        if (_.isString(latestMessage.content)) {
            return latestMessage.content;
        }

        return 'n/a';
    }

    setActiveConversation = () => {
        const conversationId = this.props.conversation.inbox_conversation_id;
        this.props.setActive(conversationId);
    };

    render() {
        const { conversation, active, activeLocationCountryCode } = this.props;
        const latestMessage = conversation.latest_message;

        const unread =
            latestMessage.user_type === 'pharmacist' && !latestMessage.viewed;

        return (
            <Fragment>
                <ListItem
                    button
                    onClick={() => this.setActiveConversation()}
                    selected={active}
                    className={`conversation-list-item ${
                        unread ? 'unread' : ''
                    }`}
                >
                    <Badge
                        color="secondary"
                        variant="dot"
                        invisible={!unread}
                        classes={{
                            badge: 'inbox-activity-avatar-badge'
                        }}
                    >
                        <ListItemAvatar>
                            <Avatar
                                style={{
                                    backgroundColor: '#EBF4FF',
                                    color: '#1A84FF',
                                    border: '1px solid #C1DDFF',
                                    boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.1)'
                                }}
                            >
                                <LocalPharmacyIcon />
                            </Avatar>
                        </ListItemAvatar>
                    </Badge>
                    <ListItemText
                        inset
                        primary={
                            <div className="conversation-list-item__primary">
                                <Typography
                                    variant="subtitle1"
                                    noWrap
                                    inline
                                    className="conversation-list-item__primary-subject"
                                    color="inherit"
                                >
                                    {conversation.subject}
                                </Typography>
                                <Typography
                                    variant="caption"
                                    noWrap
                                    inline
                                    className="conversation-list-item__primary-date"
                                >
                                    {latestMessage.created_date &&
                                        formatUTCToRelative({
                                            inputTimeString: latestMessage.created_date,
                                            countryCode: activeLocationCountryCode,
                                        })}
                                </Typography>
                            </div>
                        }
                        secondary={
                            <Typography
                                variant="body2"
                                noWrap
                                inline
                                className="conversation-list-item__secondary-text"
                            >
                                {Conversation.getContentPreview({ latestMessage })}
                            </Typography>
                        }
                    />
                </ListItem>
            </Fragment>
        );
    }
}

export class ConversationList extends Component {
    componentDidMount = () => {
        this.props.getConversations();

        Snowplow.pageView('Conversations');
    };

    componentDidUpdate = prevProps => {
        const { conversation } = this.props;
        if (
            !conversation.activeConversationId &&
            prevProps.conversation.activeConversationId
        ) {
            // Was in messages, now back on conversations screen
            Snowplow.pageView('Conversations');
        }
    };

    render() {
        const { conversation, setActiveConversation, activeLocationCountryCode } = this.props;
        const conversationComponents = conversation.conversations.map(
            conversationItem => (
                <Conversation
                    key={conversationItem.inbox_conversation_id}
                    conversation={conversationItem}
                    active={
                        conversationItem.inbox_conversation_id ===
                        conversation.activeConversationId
                    }
                    setActive={setActiveConversation}
                    activeLocationCountryCode={activeLocationCountryCode}
                />
            )
        );

        return (
            <Fragment>
                {conversation.activeConversationId ? (
                    <MessageList />
                ) : (
                    <List
                        component="nav"
                        subheader={
                            <Fragment>
                                <Toolbar disableGutters>
                                    <Typography
                                        variant="h5"
                                        color="inherit"
                                        className={`left-nav-text-padding header-nav-title-text`}
                                    >
                                        Messages
                                    </Typography>
                                </Toolbar>
                            </Fragment>
                        }
                        className="guest-inbox-app__conversation-header"
                    >
                        <div className="guest-inbox-app__conversation-list-wrap">
                            <div className="guest-inbox-app__conversation-list">
                                {conversationComponents}
                                <OverlayCircularProgress
                                    show={conversation.loading}
                                />
                            </div>
                        </div>
                    </List>
                )}
            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    const { user, conversation } = state;
    return {
        user,
        conversation,
        activeLocationCountryCode: pharmacySelectors.pharmacyActiveLocationCountryCodeSelector(state),
    };
}

const bindActionsToDispatch = dispatch => ({
    setActiveConversation: conversationId =>
        dispatch(conversationAction.setActiveConversation(conversationId)),
    clearActiveConversation: () =>
        dispatch(conversationAction.clearActiveConversation()),
    getConversations: () => dispatch(conversationAction.getConversations())
});

export default connect(
    mapStateToProps,
    bindActionsToDispatch
)(ConversationList);
