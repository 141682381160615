import conversationType from './type';


export const initialState = {
    loading: false,
    conversations: [],
    activeConversation: {},
    activeConversationId: null,
    error: {}
};


export const conversationReducer = (state = initialState, action) => {
    switch (action.type) {
        case conversationType.RESET_CONVERSATION_STORE:
            return {
                ...initialState
            };
        case conversationType.SET_ACTIVE_CONVERSATION:
            return {
                ...state,
                activeConversation: action.activeConversation,
                activeConversationId: action.activeConversationId
            };
        case conversationType.CLEAR_ACTIVE_CONVERSATION:
            return {
                ...state,
                activeConversation: {},
                activeConversationId: null
            };
        case conversationType.GET_CONVERSATIONS_REQUEST:
            return {
                ...state,
                loading: true
            };
        case conversationType.GET_CONVERSATIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                conversations: action.conversations,
            };
        case conversationType.GET_CONVERSATIONS_FAILURE:
            return {
                ...initialState,
                error: action.error
            };
        default:
            return state
    }
};


export default conversationReducer;