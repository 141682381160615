import React, {Component, Fragment} from 'react';
import _ from 'lodash';
import {connect} from 'react-redux';
import Snowplow from '../snowplow';
import {userAction} from '../redux/User';
import PharmacyPolicyModal from './PharmacyPolicyModal';
import {
    Button,
    FormControlLabel,
    Typography,
    Checkbox,
    CircularProgress
} from '@material-ui/core';

const styleH5 = {
    fontWeight: '900',
    marginBottom: '10px',
    color: '#3793ff'
};

const styleH4 = {
    fontWeight: '900',
    marginBottom: '8px',
    color: '#555454'
};

const styleBody1 = {
    fontWeight: '500',
    marginBottom: '10px',
    color: '#555454'
};

function ConsentDescription({ heading, subHeading }) {
    return (
        <Fragment>
            <Typography
                style={styleH5}
                variant="h5"
                color="primary"
                align="left"
            >
                {heading}
            </Typography>
            <Typography
                style={styleBody1}
                variant="body1"
                color="primary"
                align="left"
            >
                {subHeading}
            </Typography>
        </Fragment>
    );
}

class Consent extends Component {
    state = {
        consentContent: {},
        title: '',
        checkedPolicies: {
            message: false,
            privacyPolicy: false,
            termsConditions: false,
            pharmacyPrivacyPolicy: false
        },
        allPoliciesChecked: false,
        isOpen: false,
    };

    componentDidMount = () => {
        Snowplow.pageView('Consent');
    };

    handleChange = ({ name, consentTypeList }) => {
        return (event) => {
            const updatedCheckedPolicies = {
                ...this.state.checkedPolicies,
                [name]: event.target.checked,
            };

            const allPoliciesChecked = _.every(consentTypeList, ({ consentKey }) => {
                return updatedCheckedPolicies[consentKey];
            });

            this.setState({
                checkedPolicies: updatedCheckedPolicies,
                allPoliciesChecked,
            });
        };
    }

    handleSubmit = ({ consentTypeList }) => {
        const { submitConsent } = this.props;

        const acceptedConsents = _.map(consentTypeList, 'submitConsent');

        submitConsent(acceptedConsents);
    }
    determineConsentTypeList = () => {
        const { user } = this.props;
        const { consentTypes, userData } = user;

        const messageURL = _.get(consentTypes, 'message.text', null);
        const privacyURL = _.get(consentTypes, 'privacyPolicy.text', null);
        const tcURL = _.get(consentTypes, 'termsConditions.text', null);
        const pharmacyPrivacyPolicy = _.get(consentTypes, 'pharmacyPrivacyPolicy.text', null);

        const consentTypeList = [
            {
                consentKey: 'message',
                linkURL: messageURL,
                linkText: 'Messaging Consent',
                heading: 'Receive future Messages',
                subHeading: 'We will continue to send you reminders and updates about your refills',
                submitConsent: 'message_consent',
            },
            {
                consentKey: 'privacyPolicy',
                linkURL: privacyURL,
                linkText: 'Privacy Consent',
                heading: 'Privacy Policy',
                subHeading: 'Allows us send you messages about your specific medications and conditions',
                submitConsent: 'privacy_consent',
            },
            {
                consentKey: 'pharmacyPrivacyPolicy',
                customPolicy: pharmacyPrivacyPolicy, //this contains the text from c2 for pharmacy privacy policy
                linkText: 'Pharmacy Privacy Consent',
                heading: 'Pharmacy Privacy Policy',
                subHeading: 'Allows us send you messages about your specific medications and conditions',
                submitConsent: 'pharmacy_privacy_policy_consent',
            },
            {
                consentKey: 'termsConditions',
                linkURL: tcURL,
                linkText: 'Terms & Conditions',
                heading: 'Terms & Conditions',
                subHeading: 'More details about how we can and can not communicate with you',
                submitConsent: 'terms_conditions_consent',
            },
        ];

        return _.filter(consentTypeList, ({ consentKey, submitConsent }) => {
            const { text } = _.get(consentTypes, consentKey, {});
            const promptUserForConsent = _.get(userData, `prompt_${submitConsent}`, false)
            return promptUserForConsent && text;
        });
    };

    render() {
        const { sending } = this.props.user;
        const { allPoliciesChecked } = this.state;
        const consentTypeList = this.determineConsentTypeList();

        return (
            <Fragment>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        margin: '16px',
                        marginTop: '50px',
                        marginLeft: '75px',
                    }}
                >
                    <div style={{marginBottom: '20px'}}>
                        <Typography
                            style={styleH4}
                            variant="h4"
                            align="center"
                        >
                            Almost There!
                        </Typography>

                        {
                            _.map(consentTypeList, ({ consentKey, heading, subHeading }) => {
                                return (
                                    <ConsentDescription key={consentKey} heading={heading} subHeading={subHeading} />
                                );
                            })
                        }
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >

                            {
                                _.map(consentTypeList, ({ consentKey, linkURL, linkText, customPolicy }) => {
                                    if (consentKey.includes('pharmacyPrivacyPolicy'))
                                        return (
                                            <FormControlLabel
                                                key={consentKey}
                                                control={
                                                    <Checkbox
                                                        checked={this.state.checked}
                                                        onChange={this.handleChange({ name: consentKey, consentTypeList })}
                                                        value="checked"
                                                        color="primary"
                                                    />
                                                }
                                                labelPlacement="end"
                                                style={{marginRight: "0", marginBottom: '8px'}}
                                                classes={{label: 'label-no-margin'}}
                                                label={
                                                    <div>
                                                        <PharmacyPolicyModal customPolicy={ customPolicy } />
                                                    </div>
                                                }
                                            />
                                        );
                                    else
                                        return (
                                            <FormControlLabel
                                                key={consentKey}
                                                control={
                                                    <Checkbox
                                                        checked={this.state.checked}
                                                        onChange={this.handleChange({ name: consentKey, consentTypeList })}
                                                        value="checked"
                                                        color="primary"
                                                    />
                                                }
                                                labelPlacement="end"
                                                style={{marginRight: "0", marginBottom: '8px'}}
                                                classes={{label: 'label-no-margin'}}
                                                label={
                                                    <div>
                                                        <span>I agree to the </span>
                                                        <a href={linkURL} target="_blank" rel="noopener noreferrer"> {linkText}</a>
                                                    </div>
                                                }
                                            />
                                        );
                                })
                            }
                        </div>
                        <div
                            style={{marginTop: '24px', alignSelf: 'flex-end', marginRight: '20px'}}
                        >
                            <Button
                                variant="contained"
                                color="primary"
                                size="large"
                                fullWidth
                                disabled={!allPoliciesChecked || sending}
                                onClick={() => {
                                    this.handleSubmit({ consentTypeList })
                                }}
                            >
                                {sending ? (
                                    <CircularProgress size={20}/>
                                ) : (
                                    'CONTINUE'
                                )}
                            </Button>
                        </div>
                    </div>
                </div>
            </Fragment>

        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        pharmacy: state.pharmacy,
    };
};

const bindActionsToDispatch = dispatch => ({
    submitConsent: consentTypes =>
        dispatch(userAction.submitConsent(consentTypes))
});

export default connect(
    mapStateToProps,
    bindActionsToDispatch
)(Consent);