import axios from 'axios';



const getConversations = (userId) => {

    return axios.get(`/api/v1/inbox/patient/conversations/${userId}/activity`).then((response) => {
        return response.data.conversations_activity;
    }).catch((error) => {
        return Promise.reject(error);
    });

};

export const conversationService = {
    getConversations,
};

export default conversationService