import moment from 'moment';
import _ from 'lodash';

export const pluralize = (count) => {
    return count > 1 ? 's' : '';
};

export const getTimeDiff = (inputTimeString, baseTime = moment(), returnFormat = 'seconds') => {
    const localInputTime = moment.utc(inputTimeString).local();
    const localBaseTime = moment.utc(baseTime).local();
    return localBaseTime.diff(localInputTime, returnFormat);
};

const timeFormatByCountryCode = {
    GB: {
        sameDay: 'h:mm a',
        withinThreeDays: 'ddd h:mm a',
        lessThanYear: 'D MMM h:mm a',
        greaterThanYear: 'DD/MM/YYYY h:mm a',
    },
    US: {
        sameDay: 'h:mm a',
        withinThreeDays: 'ddd h:mm a',
        lessThanYear: 'MMM D, h:mm a',
        greaterThanYear: 'MM/DD/YYYY h:mm a',
    },
};

export const formatUTCToRelative = ({ inputTimeString, baseTime = moment(), countryCode = 'US' }) => {
    const localInputTime = moment.utc(inputTimeString).local();
    const localBaseTime = baseTime.local();
    // Creating 'start of day' references to avoid weird dayDiff logic, startOf mutates the moment object requiring fresh moment instances
    // This prevents formatting to switch on what a user thinks is the same day.
    // For example: A message sent 3 days ago at 11:43 local time, will show in one format at 11:42, but show in a different format at 11:44 today
    const localInputStartOfDay = moment.utc(inputTimeString).local().startOf('day');
    const localBaseTimeStartOfDay = moment(baseTime).local().startOf('day');
    const startOfDayDiff = localBaseTimeStartOfDay.diff(localInputStartOfDay, 'days');

    const minutesDiff = localBaseTime.diff(localInputTime, 'minutes');
    const dayDiff = localBaseTime.diff(localInputTime, 'days');
    const hoursDiff = localBaseTime.diff(localInputTime, 'hours');
    const yearsDiff = localBaseTime.diff(localInputTime, 'years');

    const formats = _.get(timeFormatByCountryCode, countryCode, timeFormatByCountryCode.US);
    if (minutesDiff < 1) {
        // Less than a minute ago
        // `Now`
        return 'Now';
    } else if (hoursDiff < 1) {
        // Less than 1 hour ago
        // `39 minutes ago`
        return `${minutesDiff} minute${pluralize(minutesDiff)} ago`;
    } else if (hoursDiff < 6) {
        // Less than 6 hours ago
        // `5 hours ago`
        return `${hoursDiff} hour${pluralize(hoursDiff)} ago`;
    } else if (dayDiff < 1) {
        // Still on the same day
        // `5:32 pm`
        return localInputTime.format(formats.sameDay);
    } else if (startOfDayDiff <= 3) {
        // Within 3 days ago, utilizing start of day to avoid mid-day display switching for the same date
        // `Tue` (conversation list) : `Tue 5:32 pm` (message list)
        return localInputTime.format(formats.withinThreeDays);
    } else if (yearsDiff < 1) {
        // Less than a year ago
        // `Jan 3` (conversation list) : `Jan 3, 5:32 pm` (message list)
        return localInputTime.format(formats.lessThanYear);
    } else {
        // Over a year
        // `03/12/2017` (conversation list) : `03/12/2017 5:32 pm` (message list)
        return localInputTime.format(formats.greaterThanYear);
    }
};