import React, { Component} from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import './Message.css';


export default class OverlayCircularProgress extends Component {
	render() {
		const { show } = this.props;
		return (
			<div className={`overlay-circular-progress ${show ? 'show' : ''}`}>
				<CircularProgress className="overlay-circular-progress-child" />
			</div>
		);
	}
}