export class GlobalSiteContext {
    constructor() {
        this.schema = "iglu:com.digitalpharmacist/site/jsonschema/1-0-0";
        this.data = {
            pharmacyId: null,
            locationId: null
        }
    }

    setPharmacyId(pharmacyId) {
        this.data.pharmacyId = pharmacyId;
        return this;
    }

    setLocationId(locationId) {
        this.data.locationId = locationId;
        return this;
    }

    build() {
        return {schema: this.schema, data: this.data}
    }
}

export class EventReferenceContext {
    constructor() {
        this.schema = "iglu:com.digitalpharmacist/event_ref/jsonschema/1-0-0"
        this.data = {
            'parentEventRef': null,
            'eventRef': null,
        }
    }

    setParentEventRef(parentEventRef) {
        this.data.parentEventRef = parentEventRef
        return this;
    }

    setEventRef(eventRef) {
        this.data.eventRef = eventRef
        return this;
    }

    build() {
        return {schema: this.schema, data: this.data}
    }
}

export class InboxMessageContext {
    constructor() {
        this.schema = "iglu:com.digitalpharmacist/inbox_message/jsonschema/1-0-0"
        this.data = {
            conversationId: null,
            messageId: null,
            patientInboxUserId: null
        }
    }

    setConversationId(conversationId) {
        this.data.conversationId = conversationId;
        return this;
    }

    setMessageId(messageId) {
        this.data.messageId = messageId;
        return this;
    }

    setPatientInboxUserId(patientInboxUserId) {
        this.data.patientInboxUserId = patientInboxUserId;
        return this;
    }

    build() {
        return {schema: this.schema, data: this.data}
    }
}
