import { socketType } from './type';
import io from "socket.io-client"
import uuid4 from 'uuid/v4';


const inboxMessageType = 'createMessage';

let socket;

const registerSocketListeners = (dispatch) => {
    socket.on('connect', () => {
        dispatch(socketConnected());
    });

    socket.on(inboxMessageType, function(msg) {
        dispatch(receiveMessage(inboxMessageType, JSON.parse(msg.attributes)));
    });

    socket.on('disconnect', () => {
        dispatch(socketDisconnected());
    });
};

const connectSocket = (userId) => {
    return function(dispatch) {
        const socketUrl = window.ENV_CONFIG.websocketUrl;
        const roomUri = `/patient/${userId}`;

        socket = io(
            socketUrl,
            {
                query: {
                    room: roomUri
                }
            }
        );
        registerSocketListeners(dispatch);
    }
};


const socketConnected = () => {
    return {
        type: socketType.SOCKET_CONNECTED
    }
};

const disconnectSocket = () => {
    return function() {
        if (socket) {
            socket.disconnect();
        }
    }
};


const socketDisconnected = () => {
    return {
        type: socketType.SOCKET_DISCONNECTED
    }
};

const receiveMessage = (messageType, messageData) => {
    return {
        type: socketType.SOCKET_RECEIVE_MESSAGE,
        payload: {
            messageType,
            messageData,
            id: uuid4()
        }
    }
};


export const socketAction = {
    connectSocket,
    socketConnected,
    disconnectSocket,
    socketDisconnected,
    receiveMessage
};

export default socketAction;
