import React, { Component } from 'react';
import './App.css';
import Inbox from "./components/Inbox";
import { TplWrap } from "./components/Inbox";

class App extends Component {

    render() {
        return (
            <TplWrap>
              <Inbox/>
            </TplWrap>
        );
    }
}

export default App;
