import axios from 'axios';
import moment from 'moment';

const createAttachment = (inbox_user_id, conversationId, selectedFile) => {
    const attachmentData = new FormData();
    attachmentData.append('attachment', selectedFile, selectedFile.name);
    attachmentData.append('inbox_user_id', inbox_user_id);
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    };

    return axios
        .post(`/api/v1/inbox/patient/conversation/${conversationId}/message/attachment`, attachmentData, config)
        .then((response) => {
            if (response.data.success) {
                return response.data;
            } else {
                return Promise.reject({});
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

const getMessages = (conversationId) => {
    return axios
        .get(`/api/v1/inbox/patient/conversation/${conversationId}/messages`)
        .then((response) => {
            if (response.data.success && response.data.messages) {
                return response.data.messages;
            } else {
                return Promise.reject({});
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

const createMessage = (conversationId, userId, content) => {
    const requestBody = {
        inbox_user_id: userId,
        content: content,
    };
    return axios
        .post(`/api/v1/inbox/patient/conversation/${conversationId}/message`, requestBody)
        .then((response) => {
            // TODO need backend to change message_id to inbox_message_id
            if (response.data.success && response.data.message_id) {
                return response.data;
            } else {
                return Promise.reject({});
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

const getLatestMessage = (conversationId) => {
    return axios
        .get(`/api/v1/inbox/patient/conversation/${conversationId}/message/latest`)
        .then((response) => {
            if (response.data.success && response.data.latest_message) {
                return response.data.latest_message;
            } else {
                return Promise.reject({});
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

const updateMessageReadStatus = (pharmacyId, locationId, conversationId) => {
    return axios
        .put(
            `/api/v1/inbox/patient/message/pharmacies/${pharmacyId}/locations/${locationId}/conversation/${conversationId}/mark-messages-read`,
            {
                user_type: 'pharmacist',
                inbox_conversation_id: conversationId,
            }
        )
        .then((response) => {
            if (response.success) {
                return true;
            } else {
                return Promise.reject({});
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

const recordAssessmentAnswers = (pharmacyId, locationId, inboxMessageId, answers) => {
    return axios
        .post(
            `/api/v1/inbox/patient/assessments/record-answers/${pharmacyId}/${locationId}/${inboxMessageId}`,
            JSON.stringify(answers),
            { headers: { 'Content-Type': 'application/json' } }
        )
        .then((response) => {
            if (response.status !== 200) {
                return Promise.reject({});
            } else {
                return response.data;
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

const fetchCalendar = (pharmacyId, locationId, calendarId) => {
    return axios
        .get(`/api/v1/appts/patient/${pharmacyId}/location/${locationId}/calendar/${calendarId}`)
        .then((response) => {
            if (response.status !== 200) {
                return Promise.reject({});
            } else {
                return response.data;
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

const fetchReservationSession = (pharmacyId, locationId, reservationSessionId) => {
    return axios
        .get(`/api/v1/appts/patient/${pharmacyId}/location/${locationId}/session/${reservationSessionId}`)
        .then((response) => {
            if (response.status !== 200) {
                return Promise.reject({});
            } else {
                return response.data;
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

const fetchAvailableSlots = (pharmacyId, locationId, calendarId, reservationSessionId) => {
    return axios
        .get(
            `/api/v1/appts/patient/${pharmacyId}/location/${locationId}/calendar/${calendarId}/session/${reservationSessionId}/slots`
        )
        .then((response) => {
            if (response.status !== 200) {
                return Promise.reject({});
            } else {
                return response.data;
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

const createReservation = (pharmacyId, locationId, calendarId, reservation) => {
    return axios
        .post(
            `/api/v1/appts/patient/${pharmacyId}/location/${locationId}/calendar/${calendarId}/reservation`,
            reservation
        )
        .then((response) => {
            if (response.status !== 200) {
                return Promise.reject({});
            } else {
                return response.data;
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

function checkInReservation(pharmacyId, locationId, calendarId, reservationId, user_id) {
    return axios
        .put(
            `/api/v1/appts/patient/${pharmacyId}/location/${locationId}/calendar/${calendarId}/reservation/${reservationId}/check-in`,
            {
                user_id,
                user_type: 'patient',
                check_in_date: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
            }
        )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export const messageService = {
    getMessages,
    createMessage,
    getLatestMessage,
    updateMessageReadStatus,
    createAttachment,
    recordAssessmentAnswers,
    fetchAvailableSlots,
    fetchCalendar,
    createReservation,
    checkInReservation,
    fetchReservationSession,
};

export default messageService;
