import React, { Component, Fragment } from 'react';
import axios from 'axios';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import CircularProgress from '@material-ui/core/CircularProgress';

const CancelToken = axios.CancelToken;

class MessageAttachment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            image: null,
            imageDataUri: null,
            showAttachment: false,
            isOpen: false,
        };
        this.cancelImageLoad = null;
    }

    componentDidMount() {
        this.getImage();
    }

    componentWillUnmount() {
        if (this.cancelImageLoad) {
            this.cancelImageLoad();
        }
    }

    getImage = () => {
        const { message } = this.props;
        const imageUrl = `/api/v1/inbox/patient/conversation/${message.inbox_conversation_id}/message/${message.inbox_message_id}/attachment`;
        const that = this;

        axios
            .get(imageUrl, {
                responseType: 'blob',
                cancelToken: new CancelToken(function executor(c) {
                    // An executor function receives a cancel function as a parameter
                    that.cancelImageLoad = c;
                }),
            })
            .then(function (response) {
                // Request finished, reset cancelImageLoad
                that.cancelImageLoad = null;

                const reader = new window.FileReader();
                reader.readAsDataURL(response.data);
                reader.onload = function () {
                    that.setState({
                        image: response.data,
                        imageDataUri: reader.result,
                    });
                };
            })
            .catch((error) => {
                // Eat error for now
            });
    };

    render() {
        const { showAttachment, imageDataUri } = this.state;
        return (
            <Fragment>
                {imageDataUri ? (
                    <img
                        alt="Message Attachment"
                        src={imageDataUri}
                        style={{
                            maxHeight: '150px',
                            maxWidth: '100%',
                            cursor: 'pointer',
                        }}
                        onClick={() => this.setState({ showAttachment: !showAttachment })}
                    />
                ) : (
                    <div
                        style={{
                            width: '150px',
                            height: '150px',
                            position: 'relative',
                        }}
                    >
                        {' '}
                        <CircularProgress
                            style={{
                                position: 'absolute',
                                top: 'calc(50% - 16px)',
                                left: 'calc(50% - 16px)',
                            }}
                        />
                    </div>
                )}
                {showAttachment && (
                    <Lightbox mainSrc={imageDataUri} onCloseRequest={() => this.setState({ showAttachment: false })} />
                )}
            </Fragment>
        );
    }
}

export default MessageAttachment;
