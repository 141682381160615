
export const parseQueryString = () => {
    //initialize query string object
    var queryStringArr = document.location.search.replace('?','').split('&');
    return queryStringArr.reduce((obj, curr) => {
        const tuple = curr.split('=');
        const key = tuple[0];
        const value = tuple[1];
        obj[key] = value;
        return obj;
    }, {});
}

export default parseQueryString;